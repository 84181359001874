import BaseInput from '@Components/Form/BaseInput';
import IconInput from '@Components/Form/IconInput';
import { DictionaryValue } from '@Core/DictionaryValue';
import { Button } from '@mui/material';
import { DictionaryValueModule } from 'Modules/CTMModules';
import { useErrorHandler } from 'helpers/FormHandler';
import { FC, useEffect, useState } from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';

const initialState = (): DictionaryValue => ({
  name: '',
  description: null,
  symbol: null,
  icon: null,
  color: null,
});

interface InnerFormProps {
  readonly?: boolean;
  dictionary: string;
  onSaved: (res: any) => void;
  onClose: () => void;
}

export const InnerForm: FC<InnerFormProps> = props => {
  const { readonly, dictionary, onSaved, onClose } = props;
  const [form, setForm] = useState<DictionaryValue>(initialState());
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const errorHandler = useErrorHandler();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    return DictionaryValueModule.api
      .post({ ...form, dictionary: dictionary })
      .then(res => {
        onSaved(res);
      })
      .catch(errorHandler)
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    setForm(initialState());
  }, []);

  const updateFormValue = (value: string | null, field: keyof DictionaryValue) => {
    setForm({ ...form, [field]: value });
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <>
            <BaseInput size={{ md: 12 }} label={'Nazwa *'} value={form.name} name="name" onChange={updateFormValue} disabled={readonly} />
            <BaseInput
              size={{ md: 12 }}
              type="textarea"
              label={'Opis'}
              value={form.description}
              name="description"
              onChange={updateFormValue}
              disabled={readonly}
            />
            <BaseInput
              size={{ md: 12 }}
              label={'Symbol'}
              value={form.symbol}
              name="symbol"
              onChange={updateFormValue}
              disabled={readonly}
            />
            <IconInput
              size={{ md: 12 }}
              type="icon"
              label={'Ikona'}
              value={form.icon}
              name="icon"
              onChange={updateFormValue}
              disabled={readonly}
            />
            <BaseInput
              size={{ md: 12 }}
              type="color"
              label={'Kolor'}
              value={form.color}
              name="color"
              onChange={updateFormValue}
              disabled={readonly}
            />
          </>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center mt-3">
            <button type="button" className="btn btn-warning btn-lg ms-2" disabled={isSubmitting} onClick={onClose}>
              Anuluj
            </button>
            <button type="button" className="btn btn-success btn-lg ms-2" disabled={isSubmitting} onClick={() => handleSubmit()}>
              Zapisz
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const WrappedForm = ({ readonly = undefined, buttonProps = {}, dictionary, onSaved }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <>
      <Button color={'success'} fullWidth={true} variant={'contained'} {...buttonProps} onClick={() => setIsOpened(true)}>
        Dodaj nową wartość
      </Button>
      <Modal isOpen={isOpened} toggle={() => setIsOpened(false)} centered={true}>
        <ModalBody className="py-3 px-5">
          <InnerForm
            readonly={readonly}
            dictionary={dictionary}
            onSaved={res => {
              onSaved(res);
              setIsOpened(false);
            }}
            onClose={() => setIsOpened(false)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default WrappedForm;
