import { InvoiceTransactionType } from '@Accountancy/Invoice/Types/invoice';

export type InvoiceTransactionTypeRow = { type: InvoiceTransactionType; label: string; code: string; color: string };
export const InvoiceTransactionTypes: InvoiceTransactionTypeRow[] = [
  { type: 'TRANSACTION_ANY', label: '(dowolny)', code: 'ANY', color: '#808080' },
  { type: 'TRANSACTION_SALE_DOMESTIC', label: 'sprzedaż krajowa - S', code: 'S', color: '#FFA07A' },
  {
    type: 'TRANSACTION_EU_GOODS_DELIVERY',
    label: 'wewnątrzwspólnotowa dostawa towarów - WDT',
    code: 'WDT',
    color: '#20B2AA',
  },
  { type: 'TRANSACTION_EXPORT_OUTSIDE_EU', label: 'eksport towarów (poza UE) - EX', code: 'EX', color: '#0000FF' },
  {
    type: 'TRANSACTION_TRIANGULAR_DELIVERY',
    label: 'transakcja trójstronna (dostawa) - WTTD',
    code: 'WTTD',
    color: '#FF6347',
  },
  {
    type: 'TRANSACTION_EU_GOODS_PURCHASE',
    label: 'wewnątrzwspólnotowe nabycie towarów (podatek należny) - WNTn',
    code: 'WNTn',
    color: '#4682B4',
  },
  {
    type: 'TRANSACTION_IMPORT_SERVICES_TAXABLE',
    label: 'import usług (podatek należny) - IMUn',
    code: 'IMUn',
    color: '#6A5ACD',
  },
  {
    type: 'TRANSACTION_SALE_OUTSIDE_COUNTRY',
    label: 'sprzedaż poza terytorium kraju - SPTK',
    code: 'SPTK',
    color: '#FFD700',
  },
  {
    type: 'TRANSACTION_REVERSE_CHARGE_SALE',
    label: 'odwrotne obciążenie (sprzedaż) - OOs',
    code: 'OOs',
    color: '#2E8B57',
  },
  { type: 'TRANSACTION_EXPORT_SERVICES', label: 'eksport usług - EXU', code: 'EXU', color: '#8A2BE2' },
  {
    type: 'TRANSACTION_IMPORT_GOODS_SIMPLIFIED',
    label: 'import towarów w procedurze uproszczonej (podatek należny) - ITPUn',
    code: 'ITPUn',
    color: '#FF4500',
  },
  {
    type: 'TRANSACTION_REVERSE_CHARGE_ART17',
    label: 'odwrotne obciążenie (art. 17 ust.1 pkt 5) (podatek należny) - OODTn',
    code: 'OODTn',
    color: '#B22222',
  },
  { type: 'TRANSACTION_LIQUIDATION_INVENTORY', label: 'remanent likwidacyjny - RL', code: 'RL', color: '#228B22' },
  {
    type: 'TRANSACTION_REFUND_CASH_REGISTER',
    label: 'zwrot kwoty na zakup kas rejestrujących - ZZKR',
    code: 'ZZKR',
    color: '#DAA520',
  },
  {
    type: 'TRANSACTION_EU_TRANSPORT_PURCHASE',
    label: 'wewnątrzwspólnotowe nabycie środków transportu (podatek należny) - WNTSTn',
    code: 'WNTSTn',
    color: '#4682B4',
  },
  {
    type: 'TRANSACTION_EU_FUEL_PURCHASE',
    label: 'wewnątrzwspólnotowe nabycie paliw silnikowych (podatek należny) - WNTPSn',
    code: 'WNTPSn',
    color: '#D2691E',
  },
  {
    type: 'TRANSACTION_REVERSE_CHARGE_SERVICE',
    label: 'odwrotne obciążenie świadczenie usług - OOu',
    code: 'OOu',
    color: '#1E90FF',
  },
  {
    type: 'TRANSACTION_EU_REMOTE_SALE_SK',
    label: 'wewnątrzwspólnotowa sprzedaż towarów na odległość (SK) - WSTO-SK',
    code: 'WSTO-SK',
    color: '#9400D3',
  },
  {
    type: 'TRANSACTION_EU_REMOTE_SALE_OSS',
    label: 'wewnątrzwspólnotowa sprzedaż towarów na odległość (OSS) - WSTO-OSS',
    code: 'WSTO-OSS',
    color: '#00CED1',
  },
];

export const transactionTypeLabel = (type: InvoiceTransactionType): InvoiceTransactionTypeRow => {
  return InvoiceTransactionTypes.find(s => s.type === type) ?? { type, label: type, code: type, color: '#808080' };
};
