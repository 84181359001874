import { ClearButton } from '@Components/DataGrid/Filter/ClearButton';
import { Box, MenuItem, Popover, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const formatDate = (date, time = '') => dayjs(date).format('YYYY-MM-DD') + time;

const buildPreset = (label, start, end) => ({
  label,
  startDate: formatDate(start, ' 00:00:00'),
  endDate: formatDate(end, ' 23:59:59'),
});

const PRESETS = {
  today: buildPreset('Dziś', dayjs().startOf('day'), dayjs().endOf('day')),
  yesterday: buildPreset('Wczoraj', dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')),
  currentMonth: buildPreset('Ten miesiąc', dayjs().startOf('month'), dayjs().endOf('month')),
  lastMonth: buildPreset('Poprzedni miesiąc', dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')),
  currentYear: buildPreset('Ten rok', dayjs().startOf('year'), dayjs().endOf('year')),
  lastYear: buildPreset('Poprzedni rok', dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')),
  last7Days: buildPreset('Ostatnie 7 dni', dayjs().subtract(7, 'day'), dayjs()),
  last14Days: buildPreset('Ostatnie 14 dni', dayjs().subtract(14, 'day'), dayjs()),
  last30Days: buildPreset('Ostatnie 30 dni', dayjs().subtract(30, 'day'), dayjs()),
};

function DateRangeFilter({ column }) {
  const [startDate, setStartDate] = useState<string | null>(column.filterValue?.[`${column.id}[after]`] || null);
  const [endDate, setEndDate] = useState<string | null>(column.filterValue?.[`${column.id}[before]`] || null);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [selectedPreset, setSelectedPreset] = useState<null | string>(null);

  const handlePresetChange = (presetKey: string) => {
    const preset = PRESETS[presetKey];
    setStartDate(preset?.startDate || null);
    setEndDate(preset?.endDate || null);
    setSelectedPreset(presetKey || null);
    setPopoverAnchor(null); // Close the popover
  };

  const detectPreset = () => {
    const foundPreset = Object.keys(PRESETS).find(key => PRESETS[key].startDate === startDate && PRESETS[key].endDate === endDate);
    setSelectedPreset(foundPreset || null);
  };

  const handleValueLabel = () => {
    if (selectedPreset) return PRESETS[selectedPreset].label;
    if (startDate && endDate) return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    if (startDate) return `Od ${formatDate(startDate)}`;
    if (endDate) return `Do ${formatDate(endDate)}`;
    return '';
  };

  useEffect(() => {
    if (startDate && endDate) {
      detectPreset();
    } else {
      setSelectedPreset(null);
    }

    const filterValue = {};
    if (startDate) filterValue[`${column.id}[after]`] = formatDate(startDate, ' 00:00:00');
    if (endDate) filterValue[`${column.id}[before]`] = formatDate(endDate, ' 23:59:59');
    column.setFilter(filterValue);
  }, [startDate, endDate]);

  const handleOpen = event => {
    setPopoverAnchor(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedPreset(null);
  };

  const handleClose = () => setPopoverAnchor(null);

  const open = Boolean(popoverAnchor);
  const valueLabel = handleValueLabel();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
      <Box>
        <TextField
          label={column.Header}
          value={valueLabel}
          onClick={handleOpen}
          variant="outlined"
          sx={{
            minWidth: selectedPreset ? 150 : startDate && endDate ? 200 : 120,
            background: '#fff',
          }}
          aria-readonly
          autoComplete="off"
          inputProps={{ readOnly: true }}
        />
        <Popover open={open} anchorEl={popoverAnchor} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Box display="flex" flexDirection="column" gap={2} p={2}>
            <TextField select label="Wybierz" onChange={e => handlePresetChange(e.target.value)} value={selectedPreset || ''}>
              {Object.keys(PRESETS).map(key => (
                <MenuItem key={key} value={key}>
                  {PRESETS[key].label}
                </MenuItem>
              ))}
            </TextField>
            <DatePicker
              label="Od"
              value={startDate}
              inputFormat="YYYY-MM-DD"
              onChange={date => setStartDate(date ? dayjs(date).format('YYYY-MM-DD') : null)}
              renderInput={params => <TextField {...params} />}
            />
            <DatePicker
              label="Do"
              value={endDate}
              inputFormat="YYYY-MM-DD"
              onChange={date => setEndDate(date ? dayjs(date).format('YYYY-MM-DD') : null)}
              renderInput={params => <TextField {...params} />}
            />
          </Box>
        </Popover>
        <ClearButton clear={clearFilters} isFilter={!!(startDate || endDate || selectedPreset)} />
      </Box>
    </LocalizationProvider>
  );
}

export default DateRangeFilter;
