import Warehouse from './Warehouse';
import { Tooltip } from '@mui/material';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import CopyItemButton from 'components/DataGrid/Button/CopyItemButton';
import { SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import SecuredView from 'components/Theme/Common/SecuredView';
import { extractIRIFromRecord } from 'helpers/ModuleUtils';
import { Button } from 'reactstrap';

export type Place = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<Place>[] = [
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'warehouse.id',
    Header: 'Magazyn',
    accessor: 'warehouse.name',
    filterable: true,
    sortable: true,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Warehouse,
  },
];

const module: CTMModule<Place> = {
  id: '08fc5e2a-d98e-4a3e-b1ed-0ab908104f60',
  dataClass: 'CTM\\Warehouse\\Entity\\Warehouse\\Place',
  urlPrefix: 'warehouse-places',
  name: 'Miejsca magazynowe',
  role: 'WAREHOUSE_WAREHOUSE',
  api: {
    item: {
      get: ({ id }) => `/warehouse/places/${id}`,
      put: ({ id }) => `/warehouse/places/${id}`,
      delete: ({ id }) => `/warehouse/places/${id}`,
    },
    collection: {
      get: `/warehouse/places`,
      post: `/warehouse/places`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    prepareRecordToSave: record => {
      return {
        ...record,
        warehouse: extractIRIFromRecord(record?.warehouse),
      };
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'name', desc: false }],
    inlineComponentAppendActions: (row, listRef) => (
      <>
        {row.id && (
          <ModuleListPicker
            key={row.id}
            moduleName={'warehouse-product-quantity-inside-place'}
            onChange={undefined}
            disableClear={true}
            defaultFilters={[{ id: 'place.id', value: row.id }]}
            allowMultipleSelect={false}
          >
            <Button
              className="btn btn-warning btn-sm"
              style={{ borderRadius: 0, marginLeft: '-1px', position: 'relative', flex: '1 1 auto', height: '100%', bottom: '-0.2px' }}
            >
              <Tooltip title={'Lista towarów na danym miejscu magaznowym.'}>
                <i className="bx bx-package" />
              </Tooltip>
            </Button>
          </ModuleListPicker>
        )}
        <SecuredView role={`ROLE_CREATE_WAREHOUSE_WAREHOUSE`}>
          <CopyItemButton
            copyUrl={`warehouse/places/copy`}
            editUrl={`/modules/warehouse-places/edit/<id>`}
            id={row.id}
            description={'Tworzy nowe miejsce magazynowe na podstawie kopii obecnego.'}
          />
        </SecuredView>
      </>
    ),
  },
};

export default module;
