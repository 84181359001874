// src/components/filter.
import DateRangeFilter from '@Components/DataGrid/Filter/DateRangeFilter';
import Employee from '@Employee/Employee/Employee';
import { FormControl, MenuItem, Select } from '@mui/material';
import Contractor from 'Modules/Contractor/Contractor';
import DocumentType from 'Modules/Warehouse/DocumentType';
import classNames from 'classnames';
import DataGrid, { FilterValue } from 'components/DataGrid';
import PrintItemButton from 'components/DataGrid/Button/PrintItemButton';
import { SelectColumnFilter } from 'components/DataGrid/Filter';
//import components
import Breadcrumbs from 'components/Theme/Common/Breadcrumb';
import SecuredView, { withSecure } from 'components/Theme/Common/SecuredView';
import { Col, Container } from 'components/Theme/Grid';
import dayjs from 'dayjs';
import { createModuleColumnConfig } from 'helpers/ModuleUtils';
import { getParamByParam } from 'iso-country-currency';
import StatusPicker from 'pages/Warehouse/Document/FormComponents/StatusPicker';
import { useDocumentTypes } from 'pages/Warehouse/Document/useDocumentTypes';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ButtonGroup } from 'reactstrap';
import { useAppSelector } from 'store';

type ListProps = {
  fullView?: boolean;
  defaultFilters?: any;
};

function List(props: ListProps) {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterValue[]>([]);
  const { data: documentTypes } = useDocumentTypes();
  const fullView = props?.fullView ?? true;

  const cacheKey = () => {
    return `_ctm_list_view_V1_${window.btoa('/warehouse/documents')}`;
  };

  const defaultFilters = props?.defaultFilters ?? (JSON.parse(localStorage.getItem(cacheKey()) ?? '[]') || []);

  const { defaultCurrency } = useAppSelector(
    state => ({
      defaultCurrency: state.AppConfiguration.configuration.Multinational.defaultCurrency,
    }),
    shallowEqual,
  );
  const columns = useMemo(
    () => [
      {
        id: 'createdAt',
        Header: 'Data utworzenia',
        accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('LLL') : null),
        filterable: true,
        Filter: DateRangeFilter,
        sortable: true,
        noBreak: true,
        minWidth: 160,
        flex: 1,
      },
      {
        id: 'issueDate',
        Header: 'Data dokumentu',
        accessor: ({ issueDate }) => (issueDate ? dayjs(issueDate).locale('pl').format('LL') : null),
        filterable: true,
        Filter: DateRangeFilter,
        sortable: true,
        noBreak: true,
        minWidth: 160,
        flex: 1,
      },
      {
        id: 'number',
        Header: 'Numer dokumentu',
        accessor: 'number',
        filterable: true,
        sortable: true,
        noBreak: true,
        minWidth: 160,
        flex: 1,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: ({ id, status, type }) => <StatusPicker key={id} id={id} value={status + ''} placeEvent={type.placeEvent} />,
        filterable: true,
        sortable: true,
        Filter: SelectColumnFilter,
        filterOptions: [
          { value: null, label: 'Wszystkie' },
          { value: '1000', label: 'Utworzone' },
          { value: '2000', label: 'Zaakceptowane' },
          { value: '2100', label: 'Zakończony' },
          { value: '3000', label: 'Odrzucone' },
        ],
        minWidth: 220,
        flex: 1,
      },
      {
        id: 'type.id',
        filterable: true,
        sortable: true,
        forceHidden: column => column.filterValue,
        Header: 'Typ',
        minWidth: 160,
        flex: 1,
        ...createModuleColumnConfig('type', DocumentType),
      },
      {
        id: 'issuer.id',
        filterable: true,
        sortable: true,
        Header: 'Wystawca',
        minWidth: 160,
        flex: 1,
        ...createModuleColumnConfig('issuer', Employee),
      },
      {
        id: 'supplier',
        Header: 'Dostawca',
        filterable: true,
        sortable: true,
        minWidth: 160,
        flex: 1,
        ...createModuleColumnConfig('supplier', Contractor),
      },
      {
        id: 'recipient',
        Header: 'Odbiorca',
        filterable: true,
        sortable: true,
        minWidth: 160,
        flex: 1,
        ...createModuleColumnConfig('recipient', Contractor),
      },
      {
        id: 'totalPriceGross',
        Header: 'Wartość dokumentu',
        accessor: ({ totalPriceGross, currency }) =>
          `${(totalPriceGross ?? 0.0) / 100} ${getParamByParam('currency', currency ?? defaultCurrency ?? 'PLN', 'symbol')}`,
        filterable: true,
        sortable: true,
        noBreak: true,
        minWidth: 160,
        flex: 1,
      },
      {
        id: 'actionsStickyRight',
        Header: 'Akcje',
        filterable: false,
        sortable: false,
        width: '100px',
        flex: 1,
        accessor: row => (
          <ButtonGroup className="w-100">
            <PrintItemButton
              id={row.number ?? '_'}
              name={row.type.name}
              downloadUrl={row['@id']}
              description={'Pobierz plik PDF z dokumentem.'}
              label={false}
            />
            <SecuredView role="ROLE_EDIT_WAREHOUSE_DOCUMENT">
              <Link to={`/warehouse/documents/${row.id}/edit`} className="btn btn-primary  btn-sm">
                <i className="mdi mdi-cogs" />
              </Link>
            </SecuredView>
            <Link to={`/warehouse/documents/${row.id}`} className="btn btn-success  btn-sm">
              <i className="mdi mdi-eye" />
            </Link>
          </ButtonGroup>
        ),
      },
    ],
    [],
  );

  const selectedTypeFilter = () => {
    const selectedFilter = filters.find(el => el.id === 'type.id')?.value;

    if (!selectedFilter) {
      return 'undefined';
    }

    return selectedFilter;
  };

  const selectedTypeFilterName = () => {
    const selectedFilter = filters.find(el => el.id === 'type.id')?.value;
    return documentTypes?.find(el => el.id === selectedFilter)?.name ?? 'Wszystkie';
  };

  const saveFilters = filters => localStorage.setItem(cacheKey(), JSON.stringify(filters));

  return (
    <div className={classNames({ 'container-fluid': fullView })}>
      {fullView && <Breadcrumbs title="Dokumenty magazynowe" breadcrumbItem={'Dokumenty - ' + selectedTypeFilterName()} />}
      <DataGrid
        defaultFilters={defaultFilters}
        columns={columns}
        url={'/warehouse/documents'}
        addUrl={'/warehouse/documents/add'}
        defaultOrderBy={[{ id: 'createdAt', desc: true }]}
        allowAdd={fullView}
        refresh={refresh}
        onFilterUpdate={({ filters }) => setFilters(filters)}
        headerOverride={({ PerPageSelectorElement, AddButtonElement, ConfiguratorElement, allowChangeLimit, allowAdd, setFilter }) => {
          return (
            <div style={{ padding: 6 }}>
              <Container className="w-100 d-flex justify-content-between align-items-center" columnSpacing={0} spacing={0}>
                {allowChangeLimit && (
                  <Col className="w-100" style={{ maxWidth: 140 }}>
                    <PerPageSelectorElement />
                  </Col>
                )}
                <Col className="w-100 custom-type-filter" style={{ maxWidth: '340px' }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{ backgroundColor: '#fff', height: '38px' }}
                      value={selectedTypeFilter()}
                    >
                      <MenuItem
                        onClick={() => {
                          setFilter('type.id', undefined);
                          saveFilters([]);
                        }}
                        value={'undefined'}
                      >
                        Wszystkie
                      </MenuItem>
                      {documentTypes?.map(el => (
                        <MenuItem
                          value={el.id ?? '_'}
                          key={'select-item' + el.id}
                          onClick={() => {
                            setFilter('type.id', el.id);
                            saveFilters([{ id: 'type.id', value: el.id }]);
                          }}
                        >
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col className="w-100 d-flex" alignItems="flex-end" justifyContent="center" style={{ maxWidth: 200, textAlign: 'right' }}>
                  {allowAdd && <AddButtonElement />}
                  <ConfiguratorElement />
                </Col>
              </Container>
            </div>
          );
        }}
      />
    </div>
  );
}

4;

List.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withRouter(withSecure(List, 'ROLE_SHOW_WAREHOUSE_DOCUMENT', true)) as unknown as typeof List;
