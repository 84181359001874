import { DataGridProps } from '../DataGrid';
import RecordInputReader from './RecordInputReader';
import {
  AddOutlined,
  CheckBoxOutlineBlankOutlined,
  DeleteForeverOutlined,
  EditOutlined,
  MoreVertOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Box, Button, ButtonGroup, ListItemIcon, Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CTMRecord } from 'Modules/Core/Types/CTMModule';
import { confirmRemove } from 'common/sweetalerts';
import ListSelector, { ListSelectorRef } from 'components/Form/ListSelector';
import ModalFormWrapper, { ModalFormProps, ModalFormWrapperRef } from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import {
  ComponentProps,
  ComponentRef,
  ElementType,
  ForwardedRef,
  RefObject,
  createRef,
  forwardRef,
  memo,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

const ModalActionsHeader = ({
  module,
  afterSave,
  list,
  onlyLabel = false,
  moduleFormComponent,
  moduleFormComponentProps,
  moduleFormWrapperProps,
}) => {
  const createModal = createRef<ModalFormWrapperRef>();
  if (!module) {
    return null;
  }

  const afterSaveCb = record => {
    afterSave(record);
    createModal?.current?.close();
  };

  if (onlyLabel || (!module.configuration.form && !moduleFormComponent)) {
    return <>Akcje</>;
  }

  if (moduleFormComponent) {
    moduleFormComponentProps = { ...moduleFormComponentProps, modal: createModal };
  }

  const ModuleFormComponent = moduleFormComponent ?? ModuleForm;

  return (
    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`} alternativeContent={'Akcje'}>
      <ModalFormWrapper
        {...moduleFormWrapperProps}
        ref={createModal}
        title={`${module.configuration.name} - Dodaj`}
        form={
          <ModuleFormComponent
            list={list}
            {...moduleFormComponentProps}
            moduleName={module.configuration.urlPrefix}
            id={null}
            showContextActions={false}
            showBackButton={false}
            afterSave={afterSaveCb}
            readonly={false}
          />
        }
      >
        <Button color="success" variant="contained" startIcon={<AddOutlined />} size="small" sx={{ p: 1 }}>
          Dodaj nowy
        </Button>
      </ModalFormWrapper>
    </SecuredView>
  );
};

const ClearSelection = ({ listRef, onChange }) => {
  return (
    <Button
      color="warning"
      variant="contained"
      onClick={() => {
        onChange(null);
        listRef.current?.close();
      }}
    >
      Wyczyść wybór
    </Button>
  );
};

const SelectMultipleValues = (listRef, onMultipleSelect) => {
  const SelectMultipleValuesComponent = ({ selectedFlatRows }) => {
    return (
      <Button
        color={'success'}
        className={'w-100'}
        onClick={() => {
          onMultipleSelect(selectedFlatRows.map(row => row.original));
          listRef.current?.close();
        }}
      >
        Zatwierdź wybrane opcje
      </Button>
    );
  };

  return SelectMultipleValuesComponent;
};

type ModuleListPickerProps<T extends CTMRecord, FormComponent extends ElementType = any> = {
  moduleName: string;
  children?: any;
  onChange?: (record: T | null) => void;
  onMultipleSelect?: (records: T[]) => void;
  name?: string;
  label?: string;
  fallback?: string;
  disabled?: boolean;
  disableClear?: boolean;
  disableShowAction?: boolean;
  disableEditAction?: boolean;
  disableRemoveAction?: boolean;
  headerActionsOnlyLabel?: boolean;
  allowMultipleSelect?: boolean;
  selectedIds?: string[];
  overrideUrl?: string;
  moduleFormComponent?: FormComponent;
  moduleFormComponentProps?: Partial<ComponentProps<FormComponent>>;
  moduleFormWrapperProps?: Partial<ModalFormProps>;
  defaultFilters?: { id: string; value: any }[];
  readerValue?: any;
  dataGridProps?: Partial<DataGridProps>;
};

type ModuleListPickerActionsProps<T extends CTMRecord> = {
  row: T;
  list: RefObject<ListSelectorRef>;
  module: any;
  onChange?: (record: T) => void;
  disableShowAction?: boolean;
  disableEditAction?: boolean;
  disableRemoveAction?: boolean;
  moduleFormWrapperProps?: Partial<ModalFormProps>;
};

function ModuleListPickerActions<T extends CTMRecord = any>(props: ModuleListPickerActionsProps<T>) {
  const {
    row,
    list,
    module,
    onChange,
    disableShowAction = false,
    disableEditAction = false,
    moduleFormWrapperProps,
    disableRemoveAction = false,
  } = props;
  const editModal = createRef<ModalFormWrapperRef>();
  const showModal = createRef<ModalFormWrapperRef>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleContextMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box className={'actions'} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ButtonGroup className="w-100" sx={{ maxWidth: 140 }}>
        {onChange && (
          <Button
            onClick={() => {
              onChange(row);
              list.current?.close();
            }}
            variant="contained"
            startIcon={<CheckBoxOutlineBlankOutlined />}
            sx={{ width: '75%', background: '#01579b', p: 1 }}
            size="small"
          >
            Wybierz
          </Button>
        )}
        <Button
          onClick={handleContextMenu}
          color="primary"
          sx={{ border: 1, background: '#fefefe', paddingRight: 1, paddingLeft: 1, color: '#01579b' }}
          size="small"
        >
          <MoreVertOutlined />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={(e, reason) => {
          setAnchorEl(null);
        }}
        onBackdropClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(null);
        }}
        MenuListProps={{
          sx: theme => ({
            overflow: 'hidden',
            margin: 0,
            border: 1,
            minWidth: 200,
            borderRadius: 1,
            paddingTop: 0,
            borderColor: theme.palette.primary.main,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          }),
        }}
      >
        {module.configuration.form && (
          <>
            {!disableShowAction && (
              <ModalFormWrapper
                {...moduleFormWrapperProps}
                ref={showModal}
                title={`${module.configuration.name} - ${module.configuration?.recordLabel?.(row, [])} - Podgląd`}
                form={
                  <ModuleForm
                    moduleName={module.configuration.urlPrefix}
                    id={row.id}
                    showContextActions={false}
                    showBackButton={false}
                    showConfigurationSwitcher={false}
                    overrideFormProps={{ trackTabInUrl: false, showHeader: false }}
                    afterSave={() => {
                      showModal.current?.close();
                      list.current?.refresh();
                      setAnchorEl(null);
                    }}
                    readonly={true}
                  />
                }
              >
                <MenuItem
                  key={`row_${row?.['@id'] ?? row?.id}_show`}
                  sx={{
                    background: '#fefefe',
                  }}
                >
                  <ListItemIcon>
                    <VisibilityOutlined sx={theme => ({ color: theme.palette.primary.main })} />
                  </ListItemIcon>
                  <Typography variant="inherit">Podgląd</Typography>
                </MenuItem>
              </ModalFormWrapper>
            )}
            {!disableEditAction && (
              <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
                <ModalFormWrapper
                  {...moduleFormWrapperProps}
                  ref={editModal}
                  title={`${module.configuration.name} - ${module.configuration.recordLabel?.(row, [])} - Edytuj`}
                  form={
                    <ModuleForm
                      moduleName={module.configuration.urlPrefix}
                      id={row.id}
                      showContextActions={false}
                      showBackButton={false}
                      overrideFormProps={{ trackTabInUrl: false, showHeader: false }}
                      afterSave={() => {
                        editModal.current?.close();
                        list.current?.refresh();
                      }}
                      readonly={false}
                    />
                  }
                >
                  <MenuItem
                    key={`row_${row?.['@id'] ?? row?.id}_edit`}
                    sx={{
                      background: '#fefefe',
                    }}
                  >
                    <ListItemIcon>
                      <EditOutlined sx={theme => ({ color: theme.palette.secondary.main })} />
                    </ListItemIcon>
                    <Typography variant="inherit">Edytuj</Typography>
                  </MenuItem>
                </ModalFormWrapper>
              </SecuredView>
            )}
            {!disableRemoveAction && (
              <MenuItem
                key={`row_${row?.['@id'] ?? row?.id}_delete`}
                onClick={() =>
                  confirmRemove(() =>
                    module.api.delete({ id: row.id }).then(() => {
                      list.current?.refresh();
                      setAnchorEl(null);
                    }),
                  )
                }
                sx={{
                  background: '#fefefe',
                }}
              >
                <ListItemIcon>
                  <DeleteForeverOutlined sx={theme => ({ color: theme.palette.error.main })} />
                </ListItemIcon>
                <Typography variant="inherit">Usuń</Typography>
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </Box>
  );
}

type ModuleListPickerHandle = {
  open: (paremeters?: any[]) => void;
};
export type ModuleListPickerRef = ComponentRef<typeof ModuleListPicker>;

const ModuleListPicker = forwardRef<ModuleListPickerHandle, ModuleListPickerProps<any>>((props, ref) => {
  const {
    moduleName,
    children,
    onChange,
    onMultipleSelect,
    name = '',
    label = '',
    fallback = '',
    disabled = false,
    disableClear = false,
    disableShowAction = false,
    disableEditAction = false,
    disableRemoveAction = false,
    headerActionsOnlyLabel = false,
    allowMultipleSelect = false,
    selectedIds = [],
    overrideUrl,
    moduleFormComponent,
    moduleFormComponentProps = { overrideFormProps: { trackTabInUrl: false } },
    moduleFormWrapperProps,
    defaultFilters,
    dataGridProps,
    readerValue = null,
  } = props;
  const module = useModuleContext(moduleName);
  if (!module) {
    throw 'Module not found ' + moduleName;
  }
  const list = createRef<ListSelectorRef>();
  useImperativeHandle(ref, () => ({
    open(parameters) {
      list?.current?.open(parameters);
    },
  }));
  const modalListActions = useMemo(
    () => [
      {
        id: 'actionsStickyRight',
        Header: () => (
          <ModalActionsHeader
            list={list.current}
            module={module}
            onlyLabel={headerActionsOnlyLabel}
            moduleFormComponent={moduleFormComponent}
            moduleFormComponentProps={moduleFormComponentProps}
            moduleFormWrapperProps={moduleFormWrapperProps}
            afterSave={record => {
              onChange?.(record);
              list?.current?.close();
            }}
          />
        ),
        disableSortBy: true,
        disableFilters: true,
        disableExpandAction: true,
        accessor: row => (
          <ModuleListPickerActions
            list={list}
            module={module}
            onChange={onChange}
            row={row}
            moduleFormWrapperProps={moduleFormWrapperProps}
            disableEditAction={disableEditAction}
            disableRemoveAction={disableRemoveAction}
            disableShowAction={disableShowAction}
          />
        ),
      },
    ],
    [list, module, onChange, moduleFormComponent, moduleFormWrapperProps],
  );
  const columns = useMemo(() => [...(module?.configuration?.list?.columns ?? []), ...modalListActions], [module, modalListActions]);
  const [queryKey] = useState(uuidv4());
  const computedDataGridProps = useMemo<DataGridProps>(
    () => ({
      url: overrideUrl ?? module.configuration.list?.url ?? module.api.getAllUrl,
      columns,
      className: 'table-clickable',
      responsive: true,
      allowChangeLimit: true,
      enableContextMenu: false, //@todo: change in feature
      disableTop: true,
      disableHeader: false,
      disableFooter: false,
      fullWidth: true,
      defaultPerPage: 20,
      storeFilters: true,
      defaultFilters: defaultFilters ?? module.configuration.list?.defaultFilters,
      defaultOrderBy: module.configuration.list?.defaultOrderBy,
      renderRowSubComponent: params => module.configuration.list?.renderRowSubComponent?.(params, modalListActions),
      canRenderSubRow: module.configuration.list?.canRenderSubRow,
      selectionActions: allowMultipleSelect ? SelectMultipleValues(list, onMultipleSelect) : undefined,
      selectedIds: selectedIds,
      queryKey,
      ...(dataGridProps ?? {}),
    }),
    [columns, module, defaultFilters, dataGridProps],
  );

  const dialogProps: Partial<DialogProps> = {};

  if (columns.length <= 2) {
    dialogProps.maxWidth = 'sm';
  } else if (columns.length <= 3) {
    dialogProps.maxWidth = 'md';
  } else if (columns.length <= 5) {
    dialogProps.maxWidth = 'lg';
  }

  return (
    <ListSelector
      ref={list}
      dataGridProps={computedDataGridProps}
      disabled={disabled}
      title={`${module.configuration.name} - Lista wyboru`}
      navbar={<>{!disableClear && <ClearSelection listRef={list} onChange={onChange} />}</>}
      dialogProps={dialogProps}
    >
      {typeof children !== 'undefined' && <>{children}</>}
      {typeof children === 'undefined' && (
        <RecordInputReader readonly={disabled} name={name} value={readerValue} module={module} label={label} fallback={fallback} />
      )}
    </ListSelector>
  );
});
ModuleListPicker.displayName = 'ModuleListPicker';

export default memo(ModuleListPicker) as unknown as <T extends CTMRecord, FormComponent extends ElementType = any>(
  props: ModuleListPickerProps<T, FormComponent> & { ref?: ForwardedRef<ModuleListPickerRef> },
) => any;
