import { IndexedField } from '@Components/CustomFields/CustomField';
import { Field } from '@CustomFields/Field';
import { useCallback, useMemo } from 'react';

export type InvoiceFields = {
  [key in
    | 'amountPaidGross'
    | 'amountPaidGrossHomeCurrency'
    | 'buyer'
    | 'buyerRaw.bankAccount'
    | 'buyerRaw.bankName'
    | 'buyerRaw.city'
    | 'buyerRaw.country'
    | 'buyerRaw.email'
    | 'buyerRaw.fax'
    | 'buyerRaw.flat'
    | 'buyerRaw.house'
    | 'buyerRaw.name'
    | 'buyerRaw.phone'
    | 'buyerRaw.post'
    | 'buyerRaw.province'
    | 'buyerRaw.street'
    | 'buyerRaw.taxNumber'
    | 'buyerRaw.taxNumberPrefix'
    | 'buyerRaw.www'
    | 'buyerRaw.additionalInformation'
    | 'buyerRaw.district'
    | 'correction'
    | 'parent'
    | 'transactionTypes'
    | 'currency'
    | 'correctedFields'
    | 'correctingContent'
    | 'correctContent'
    | 'description'
    | 'exchangeCurrency'
    | 'exchangeRate'
    | 'exchangeInfoDate'
    | 'externalNumber'
    | 'paymentType'
    | 'income'
    | 'issueDate'
    | 'issueAddress.city'
    | 'issueAddress.comment'
    | 'issueAddress.country'
    | 'issueAddress.district'
    | 'issueAddress.flat'
    | 'issueAddress.house'
    | 'issueAddress.post'
    | 'issueAddress.province'
    | 'issueAddress.street'
    | 'issuer'
    | 'lang'
    | 'number'
    | 'oss'
    | 'ownDescription'
    | 'paymentDate'
    | 'paymentDeadline'
    | 'payments'
    | 'positions'
    | 'reasonForCorrection'
    | 'recipient'
    | 'recipientRaw.bankAccount'
    | 'recipientRaw.bankName'
    | 'recipientRaw.city'
    | 'recipientRaw.country'
    | 'recipientRaw.district'
    | 'recipientRaw.email'
    | 'recipientRaw.fax'
    | 'recipientRaw.flat'
    | 'recipientRaw.house'
    | 'recipientRaw.name'
    | 'recipientRaw.phone'
    | 'recipientRaw.post'
    | 'recipientRaw.province'
    | 'recipientRaw.street'
    | 'recipientRaw.taxNumber'
    | 'recipientRaw.taxNumberPrefix'
    | 'recipientRaw.additionalInformation'
    | 'recipientRaw.www'
    | 'secondLang'
    | 'sellDate'
    | 'seller'
    | 'sellerRaw.bankAccount'
    | 'sellerRaw.bankName'
    | 'sellerRaw.city'
    | 'sellerRaw.country'
    | 'sellerRaw.district'
    | 'sellerRaw.email'
    | 'sellerRaw.fax'
    | 'sellerRaw.flat'
    | 'sellerRaw.house'
    | 'sellerRaw.name'
    | 'sellerRaw.phone'
    | 'sellerRaw.post'
    | 'sellerRaw.province'
    | 'sellerRaw.street'
    | 'sellerRaw.taxNumber'
    | 'sellerRaw.taxNumberPrefix'
    | 'sellerRaw.www'
    | 'sellerRaw.additionalInformation'
    | 'splitPayment'
    | 'status'
    | 'type']: IndexedField;
};

const useInvoiceFields = (fields: Field[]): InvoiceFields => {
  const indexedFields: { [key: string]: IndexedField } = useMemo(
    () =>
      fields.reduce((prev, curr, currentIndex) => {
        prev[curr.propertyPath] = { ...curr, _tabindex: currentIndex };
        return prev;
      }, {}),
    [],
  );

  const resolveField = useCallback(
    (propertyPath: string): IndexedField => {
      if (!indexedFields.hasOwnProperty(propertyPath)) {
        throw `Field not found at path "${propertyPath}"`;
      }
      return indexedFields[propertyPath];
    },
    [indexedFields],
  );

  return useMemo<InvoiceFields>(() => {
    return {
      'issueAddress.district': resolveField('issueAddress.district'),
      'recipientRaw.phone': resolveField('recipientRaw.phone'),
      amountPaidGross: resolveField('amountPaidGross'),
      amountPaidGrossHomeCurrency: resolveField('amountPaidGrossHomeCurrency'),
      'sellerRaw.street': resolveField('sellerRaw.street'),
      'recipientRaw.name': resolveField('recipientRaw.name'),
      'buyerRaw.fax': resolveField('buyerRaw.fax'),
      secondLang: resolveField('secondLang'),
      issuer: resolveField('issuer'),
      'sellerRaw.district': resolveField('sellerRaw.district'),
      'sellerRaw.www': resolveField('sellerRaw.www'),
      'sellerRaw.bankName': resolveField('sellerRaw.bankName'),
      'buyerRaw.post': resolveField('buyerRaw.post'),
      'recipientRaw.flat': resolveField('recipientRaw.flat'),
      'issueAddress.post': resolveField('issueAddress.post'),
      'buyerRaw.province': resolveField('buyerRaw.province'),
      correctedFields: resolveField('correctedFields'),
      issueDate: resolveField('issueDate'),
      payments: resolveField('payments'),
      parent: resolveField('parent'),
      oss: resolveField('oss'),
      transactionTypes: resolveField('transactionTypes'),
      'sellerRaw.name': resolveField('sellerRaw.name'),
      correction: resolveField('correction'),
      'issueAddress.house': resolveField('issueAddress.house'),
      sellDate: resolveField('sellDate'),
      number: resolveField('number'),
      'buyerRaw.bankAccount': resolveField('buyerRaw.bankAccount'),
      lang: resolveField('lang'),
      'recipientRaw.street': resolveField('recipientRaw.street'),
      paymentDeadline: resolveField('paymentDeadline'),
      exchangeCurrency: resolveField('exchangeCurrency'),
      'recipientRaw.bankName': resolveField('recipientRaw.bankName'),
      'buyerRaw.taxNumberPrefix': resolveField('buyerRaw.taxNumberPrefix'),
      'buyerRaw.district': resolveField('buyerRaw.district'),
      recipient: resolveField('recipient'),
      paymentDate: resolveField('paymentDate'),
      'issueAddress.country': resolveField('issueAddress.country'),
      ownDescription: resolveField('ownDescription'),
      reasonForCorrection: resolveField('reasonForCorrection'),
      paymentType: resolveField('paymentType'),
      'sellerRaw.email': resolveField('sellerRaw.email'),
      'sellerRaw.bankAccount': resolveField('sellerRaw.bankAccount'),
      'recipientRaw.country': resolveField('recipientRaw.country'),
      description: resolveField('description'),
      externalNumber: resolveField('externalNumber'),
      'recipientRaw.www': resolveField('recipientRaw.www'),
      'sellerRaw.post': resolveField('sellerRaw.post'),
      'issueAddress.street': resolveField('issueAddress.street'),
      currency: resolveField('currency'),
      'buyerRaw.taxNumber': resolveField('buyerRaw.taxNumber'),
      'buyerRaw.name': resolveField('buyerRaw.name'),
      'issueAddress.comment': resolveField('issueAddress.comment'),
      'buyerRaw.city': resolveField('buyerRaw.city'),
      'sellerRaw.house': resolveField('sellerRaw.house'),
      'recipientRaw.post': resolveField('recipientRaw.post'),
      'recipientRaw.bankAccount': resolveField('recipientRaw.bankAccount'),
      'buyerRaw.street': resolveField('buyerRaw.street'),
      'buyerRaw.bankName': resolveField('buyerRaw.bankName'),
      'buyerRaw.additionalInformation': resolveField('buyerRaw.additionalInformation'),
      'sellerRaw.province': resolveField('sellerRaw.province'),
      'sellerRaw.taxNumber': resolveField('sellerRaw.taxNumber'),
      'buyerRaw.country': resolveField('buyerRaw.country'),
      splitPayment: resolveField('splitPayment'),
      'issueAddress.province': resolveField('issueAddress.province'),
      'sellerRaw.taxNumberPrefix': resolveField('sellerRaw.taxNumberPrefix'),
      'sellerRaw.flat': resolveField('sellerRaw.flat'),
      'sellerRaw.country': resolveField('sellerRaw.country'),
      'buyerRaw.house': resolveField('buyerRaw.house'),
      'sellerRaw.city': resolveField('sellerRaw.city'),
      'recipientRaw.email': resolveField('recipientRaw.email'),
      'buyerRaw.www': resolveField('buyerRaw.www'),
      'recipientRaw.city': resolveField('recipientRaw.city'),
      income: resolveField('income'),
      correctingContent: resolveField('correctingContent'),
      correctContent: resolveField('correctContent'),
      'recipientRaw.province': resolveField('recipientRaw.province'),
      'sellerRaw.phone': resolveField('sellerRaw.phone'),
      'sellerRaw.fax': resolveField('sellerRaw.fax'),
      'sellerRaw.additionalInformation': resolveField('sellerRaw.additionalInformation'),
      'recipientRaw.district': resolveField('recipientRaw.district'),
      'issueAddress.flat': resolveField('issueAddress.flat'),
      'recipientRaw.house': resolveField('recipientRaw.house'),
      'recipientRaw.additionalInformation': resolveField('recipientRaw.additionalInformation'),
      exchangeRate: resolveField('exchangeRate'),
      exchangeInfoDate: resolveField('exchangeInfoDate'),
      'recipientRaw.taxNumber': resolveField('recipientRaw.taxNumber'),
      type: resolveField('type'),
      positions: resolveField('positions'),
      buyer: resolveField('buyer'),
      'recipientRaw.taxNumberPrefix': resolveField('recipientRaw.taxNumberPrefix'),
      'buyerRaw.phone': resolveField('buyerRaw.phone'),
      status: resolveField('status'),
      'recipientRaw.fax': resolveField('recipientRaw.fax'),
      'buyerRaw.email': resolveField('buyerRaw.email'),
      seller: resolveField('seller'),
      'buyerRaw.flat': resolveField('buyerRaw.flat'),
      'issueAddress.city': resolveField('issueAddress.city'),
    };
  }, [indexedFields, resolveField]);
};

export default useInvoiceFields;
