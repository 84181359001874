import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ListPicker from 'components/Form/ListPicker';
import { useEffect } from 'react';

const ChoiceField: CustomFieldComponentType = ({ field, value, onChange, readonly, errorKey, customProps = {} }) => {
  const choices = field.config.choices ?? {};
  const options = Object.keys(field.config.choices ?? {}).map(optionValue => ({ val: optionValue, label: choices[optionValue] }));

  useEffect(() => {
    if (value === null && field.defaultValue) {
      onChange(field.defaultValue, field.id);
    }
  }, []);
  return (
    <ListPicker
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={value || field.defaultValue || []}
      multiple={field.config.multiple ?? false}
      options={options}
      optionLabel="label"
      optionTrackBy="val"
      onChange={onChange}
      disabled={readonly}
      errorKey={errorKey}
      labelerId={field.labelerId}
      {...customProps}
    />
  );
};

export default ChoiceField;
