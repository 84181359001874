import { Invoice } from '@Accountancy/Invoice/Types/invoice';
import useFileDownloader from '@Core/Hooks/useFileDownloader';
import { ContextAction } from '@Core/Types/CTMModule';
import { ClientOrderModule } from '@Modules/CTMModules';
import { FileCopyOutlined, HistoryOutlined, PictureAsPdfOutlined, ShoppingBasketOutlined, SummarizeOutlined } from '@mui/icons-material';
import { useModule } from 'context/ModuleContext';
import { useHistory } from 'react-router-dom';

export function useInvoiceContextActions(record: Partial<Invoice>): ContextAction[] {
  const history = useHistory();
  const { module } = useModule();
  const downloadInvoiceFile = useFileDownloader({
    url: record['@id'],
    fileName: module.configuration.recordLabel?.(record as Invoice, []).replace(' ', '_'),
    config: {
      headers: {
        Accept: 'application/pdf',
      },
    },
  });

  const actions = [
    {
      icon: <PictureAsPdfOutlined />,
      label: 'Pobierz Dokument',
      action: () => downloadInvoiceFile(),
    },
  ];

  if (record.corrected === null) {
    actions.push({
      icon: <SummarizeOutlined />,
      label: 'Wystaw korektę',
      action: () =>
        history.push(`${module.createUrl}?income=${record.income ? 'true' : 'false'}&type=CORRECTION&fromDocument=${record.id}`),
    });
  } else {
    actions.push({
      icon: <SummarizeOutlined />,
      label: 'Zobacz korektę',
      action: () => history.push(module.showUrl(record.corrected?.id as string)),
    });
  }

  actions.push({
    icon: <SummarizeOutlined />,
    label: 'Wystaw notę korygującą',
    action: () =>
      history.push(`${module.createUrl}?income=${record.income ? 'true' : 'false'}&type=CORRECTION_NOTE&fromDocument=${record.id}`),
  });

  if (record.correction !== null) {
    actions.push({
      icon: <HistoryOutlined />,
      label: 'Zobacz dokument przed korektą',
      action: () => history.push(module.showUrl(record.correction?.id as string)),
    });
  }

  if (record.clientOrder?.id) {
    actions.push({
      icon: <ShoppingBasketOutlined />,
      label: 'Przejdź do zamówienia',
      action: () => history.push(ClientOrderModule.showUrl(record.clientOrder?.id as string)),
    });
  }

  actions.push({
    icon: <FileCopyOutlined />,
    label: 'Wystaw podobną',
    action: () =>
      history.push(`${module.createUrl}?income=${record.income ? 'true' : 'false'}&type=${record.type}&fromDocument=${record.id}`),
  });

  return actions;
}
