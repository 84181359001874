import { HighlightOff } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { ComponentProps, ComponentRef, forwardRef, useImperativeHandle, useState } from 'react';

export type ModalFormProps = {
  id?: string;
  form?: any;
  children: any;
  title: string;
  onOpen?: (id: string) => void;
  dialogProps?: Partial<ComponentProps<typeof Dialog>>;
};

export type ModalFormHandle = {
  open: () => void;
  close: () => void;
  isOpened: () => boolean;
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
  },
}));

const ModalFormWrapper = forwardRef<ModalFormHandle, ModalFormProps>((props, ref) => {
  const { id, form = null, children, title, onOpen = undefined, dialogProps = {} } = props;
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
      onOpen?.(id ?? '');
    },
    close() {
      setOpen(false);
    },
    isOpened() {
      return true === open;
    },
  }));

  if (!open) {
    return (
      <div
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
          onOpen?.(id ?? '');
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <>
      <div
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        {children}
      </div>
      <StyledDialog
        open={open}
        onClose={(event, reason) => {
          //@ts-ignore
          event?.stopPropagation();
          setOpen(false);
        }}
        fullWidth={true}
        maxWidth={false}
        sx={{ maxWidth: '90vw', margin: 'auto' }}
        {...dialogProps}
      >
        <DialogTitle
          sx={theme => ({ m: 0, p: 1, color: theme.palette.common.white, background: theme.palette.primary.main })}
          onClick={e => e.stopPropagation()}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={e => {
            setOpen(false);
            e.stopPropagation();
          }}
          sx={theme => ({
            position: 'absolute',
            right: 8,
            top: 4,
            color: theme.palette.common.white,
          })}
        >
          <HighlightOff />
        </IconButton>
        <DialogContent dividers onClick={e => e.stopPropagation()}>
          {open && <>{typeof form === 'function' ? form() : form}</>}
        </DialogContent>
      </StyledDialog>
    </>
  );
});
ModalFormWrapper.displayName = 'ModalFormWrapper';

export type ModalFormWrapperRef = ComponentRef<typeof ModalFormWrapper>;
export default ModalFormWrapper;
