import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import InvoiceField from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceField';
import { InvoicePosition, InvoiceType } from '@Accountancy/Invoice/Types/invoice';
import { Col, Container } from '@Components/Theme/Grid';
import CurrencyExchange from '@Core/Services/CurrencyExchange';
import { Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useAppDispatch } from 'store';
import { addSingleToast } from 'store/Toast/actions';

const InvoiceGeneralFieldsGroup: FC<InvoiceGroupProps> = props => {
  const exchangeDate = dayjs(props.values[props.fields.exchangeInfoDate.id] ?? dayjs().subtract(1, 'day')).format('YYYY-MM-DD');
  const sellDate = dayjs(props.values[props.fields.sellDate.id] ?? new Date()).format('YYYY-MM-DD');
  const currency = props.values[props.fields.currency.id] ?? 'PLN';
  const exchangeRate = props.values[props.fields.exchangeRate.id];
  const type: InvoiceType = props.values[props.fields.type.id];

  const dispatch = useAppDispatch();

  useEffect(() => {
    CurrencyExchange.getCurrencyExchangeRate('PLN', currency, exchangeDate).then(result => {
      if (result.getMid() !== exchangeRate) {
        props.onUpdate(result.getMid(), props.fields.exchangeRate.id);
        props.onUpdate(result.getDate(), props.fields.exchangeInfoDate.id);
        const positions: InvoicePosition[] = props.values[props.fields.positions.id];
        const exchangedPositions: InvoicePosition[] = positions.map<InvoicePosition>(position => {
          let newUnitPrice: number;

          if (exchangeRate > result.getMid()) {
            newUnitPrice = parseFloat((position.priceUnitNet * (exchangeRate / result.getMid())).toFixed(2));
          } else {
            newUnitPrice = parseFloat((position.priceUnitNet / result.getMid()).toFixed(2));
          }

          const vatRate = 1 + (position.tax?.value ?? 0) / 100;

          return {
            ...position,
            priceUnitNet: newUnitPrice,
            priceTotalNet: newUnitPrice * position.quantity,
            priceUnitGross: parseFloat((newUnitPrice * vatRate).toFixed(2)),
            priceTotalGross: parseFloat((newUnitPrice * vatRate * position.quantity).toFixed(2)),
          };
        });
        props.onUpdate(exchangedPositions, props.fields.positions.id);
        dispatch(
          addSingleToast({
            title: `Pobrano kurs walut z dnia ${result.getDate()}`,
            config: { appearance: 'success' },
          }),
        );
      }
    });
  }, [currency, exchangeDate, exchangeRate]);

  useEffect(() => {
    if (sellDate && dayjs(sellDate).subtract(1, 'day').format('Y-m-d') !== exchangeDate) {
      props.onUpdate(dayjs(sellDate).subtract(1, 'day'), props.fields.exchangeInfoDate.id);
    }
  }, [sellDate]);

  return (
    <Paper sx={{ minHeight: 180, paddingBottom: 3 }}>
      <Container>
        <Col xs={12}>
          <Typography variant="subtitle1" align="center">
            Ogólne
          </Typography>
        </Col>
        {type !== 'CORRECTION_NOTE' && (
          <Col xs={12} md={3}>
            <InvoiceField {...props} fieldKey={'status'} />
          </Col>
        )}

        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'number'} readonly={true} />
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'externalNumber'} />
        </Col>
        {type !== 'CORRECTION_NOTE' && (
          <Col xs={12} md={3}>
            <InvoiceField {...props} fieldKey={'currency'} />
          </Col>
        )}
        {type !== 'CORRECTION_NOTE' && (
          <>
            {props.values[props.fields.currency.id] !== 'PLN' && (
              <Col xs={12} md={3}>
                <InvoiceField key={currency} {...props} fieldKey={'exchangeRate'} />
              </Col>
            )}
            {props.values[props.fields.currency.id] !== 'PLN' && (
              <Col xs={12} md={3}>
                <InvoiceField key={currency} {...props} fieldKey={'exchangeInfoDate'} />
              </Col>
            )}
          </>
        )}
        {type !== 'CORRECTION_NOTE' && (
          <Col xs={12} md={3}>
            <InvoiceField key={currency} {...props} fieldKey={'paymentType'} />
          </Col>
        )}
        {type === 'CORRECTION_NOTE' && (
          <Col xs={12} md={3}>
            <InvoiceField key={currency} {...props} fieldKey={'parent'} fieldOverride={{ name: 'Faktura korygowana' }} readonly={true} />
          </Col>
        )}
        {type === 'CORRECTION' && (
          <Col xs={12} md={3}>
            <InvoiceField
              key={currency}
              {...props}
              fieldKey={'correction'}
              fieldOverride={{ name: 'Faktura korygowana' }}
              readonly={true}
            />
          </Col>
        )}
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'lang'} />
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'secondLang'} />
        </Col>
        <Col xs={12} md={3}>
          <InvoiceField {...props} fieldKey={'issuer'} />
        </Col>
        <Col xs={12}>
          <InvoiceField {...props} fieldKey={'transactionTypes'} />
        </Col>
      </Container>
    </Paper>
  );
};

export default InvoiceGeneralFieldsGroup;
