import Loader from '@Components/Theme/Common/Loader';
import { FormConfiguration } from '@Core/Types/CTMModule';
import { Module } from '@CustomFields/CoreModule';
import { Template } from '@CustomFields/Template';
import { Paper } from '@mui/material';
import { TemplateModule } from 'Modules/CTMModules';
import TemplateSelector from 'Modules/CustomFields/CoreComponents/TemplateSelector';
import TemplateSelectorSelect from 'Modules/CustomFields/ModalComponents/TemplateSelector';
import ModuleTemplatesBuilder, { ModuleTemplatesBuilderHandle } from 'Modules/CustomFields/ModuleTemplatesBuilder';
import classnames from 'classnames';
import CustomTab from 'components/CustomFields/CustomTab';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModule, useModuleFields } from 'context/ModuleContext';
import { get } from 'helpers/Axios';
import { setValue } from 'helpers/PropertyAccessor';
import { FC, Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'store';
import { clearErrors } from 'store/form/errors/actions';

export type TabNavLinkProps = {
  onDoubleClick: () => void;
  onClick: () => void;
  tab: Template['tabs'][0];
  activeTab: string | number;
};

const TabNavLink: FC<TabNavLinkProps> = props => {
  const { onDoubleClick, onClick, tab, activeTab } = props;
  const moduleFields = useModuleFields();
  const { violations } = useAppSelector(
    state => ({
      violations: state.FormErrors.violations,
    }),
    shallowEqual,
  );

  const hasTabError = useMemo(() => {
    const fieldsInTab: string[] = tab.sections
      .map(s => s.layout?.map(el => el.i) ?? [])
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []);

    return fieldsInTab.find(
      fieldInTab =>
        violations.hasOwnProperty(moduleFields.find(el => el.id === fieldInTab)?.propertyPath ?? '_') ||
        violations.hasOwnProperty(fieldInTab),
    );
  }, [tab, violations, moduleFields]);

  return (
    <NavItem key={`NavItem_${tab.id}`}>
      <NavLink
        style={{ cursor: 'pointer' }}
        className={classnames({
          active: activeTab === tab.id,
          'dark-accent-color': activeTab !== tab.id,
        })}
        onClick={onClick}
      >
        <span
          onDoubleClick={onDoubleClick}
          style={{
            position: 'relative',
            paddingRight: hasTabError ? 20 : 0,
          }}
        >
          {hasTabError && (
            <i
              className="mdi mdi-alert-circle text-danger font-size-22"
              style={{
                position: 'absolute',
                right: -10,
                top: -6,
              }}
            />
          )}
          {tab.name || 'Nazwa zakładki'}
        </span>
      </NavLink>
    </NavItem>
  );
};

export type ApiBaseFormHeaderProps<R = object> = {
  readonly: boolean;
  record: Record<R>;
  template: Module['templates'][0];
  templates: Module['templates'];
  recordLabel?: (record: any, fields: any) => string;
  handleChangeTemplate: (templateId: string) => void;
  inlineComponentAppendHeader?: (record: any) => any;
  contextActions?: (record: any) => any;
  showConfigurationSwitcher?: boolean;
  onSubmit?: false | ((record: any, fields: any[]) => Promise<boolean>);
  handleSubmit: (event: any, backToList: boolean) => void;
  showBackButton?: boolean;
  isSubmitting?: boolean;
  backUrl?: string;
  templateBuilder: {
    afterSave: () => void;
  };
};

const ApiBaseFormHeader: FC<ApiBaseFormHeaderProps> = props => {
  const {
    readonly,
    template,
    templates,
    templateBuilder,
    handleChangeTemplate,
    recordLabel,
    record,
    contextActions,
    showConfigurationSwitcher,
    showBackButton,
    backUrl,
    isSubmitting,
    onSubmit,
    inlineComponentAppendHeader,
    handleSubmit,
  } = props;
  const { moduleData } = useModule();

  const moduleTemplatesBuilder = useRef<ModuleTemplatesBuilderHandle>(null);
  const history = useHistory();

  return (
    <Row className={classnames('mb-2 form-sticky-header')}>
      <Col xs={12} md={6} className="align-self-center">
        <h4>
          {moduleData.name} {recordLabel && recordLabel(record, moduleData.fields) ? `- ${recordLabel(record, moduleData.fields)}` : ''} -{' '}
          {readonly ? 'Podgląd' : record['@id'] ? 'Edytuj' : 'Dodaj'}
        </h4>
      </Col>
      {contextActions && (
        <Col xs={12} md={6} className="align-self-center justify-content-end mb-md-2 text-end">
          {contextActions(record)}
        </Col>
      )}
      <Col xs={12} md={3} className="align-self-center d-flex">
        {showConfigurationSwitcher && (
          <div>
            <SecuredView role="ROLE_EDIT_CONFIGURATION">
              <ModuleTemplatesBuilder
                ref={moduleTemplatesBuilder}
                templateId={template?.id}
                afterSave={templateBuilder.afterSave}
                wrapperProps={{ style: { display: 'inline-block' } }}
              >
                <button className="btn btn-primary" onClick={() => moduleTemplatesBuilder?.current?.open()}>
                  <i className={'mdi mdi-cogs'} /> {readonly && 'Tryb konfiguracji pól'}
                </button>
              </ModuleTemplatesBuilder>
            </SecuredView>
          </div>
        )}
        {!readonly && (
          <div style={{ width: '100%', paddingLeft: 6 }}>
            <TemplateSelectorSelect
              loading={false}
              templates={templates}
              selectedTemplate={template?.id ?? null}
              handleChangeTemplate={handleChangeTemplate}
              wrapperComponent={Fragment}
            />
          </div>
        )}
      </Col>
      <Col xs={12} md={9} className="text-end">
        {showBackButton && (
          <button
            className="btn btn-primary ml-1"
            onClick={() => {
              if (backUrl) {
                history.push(backUrl);
              } else {
                history.goBack();
              }
            }}
          >
            <i className="mdi mdi-arrow-left" />
            Wróć do listy
          </button>
        )}

        {onSubmit !== false && !readonly && (
          <button className="btn btn-primary ml-1" type="submit" disabled={isSubmitting} onClick={e => handleSubmit(e, false)}>
            <i className={isSubmitting ? 'mdi mdi-spin mdi-cogs' : 'mdi mdi-content-save'} /> Zapisz
          </button>
        )}
      </Col>
      {inlineComponentAppendHeader?.(record)}
    </Row>
  );
};

export type BaseFromProps = {
  onSubmit?: false | ((record: any, fields: any[]) => Promise<boolean>);
  readonly?: boolean;
  className?: string;
  contextActions?: (record: any) => any;
  submitActions?: (record: any) => any;
  inlineComponentAppendHeader?: (record: any) => any;
  fieldComponents?: FormConfiguration['fieldComponents'];
  sectionComponents?: FormConfiguration['sectionComponents'];
  recordLabel?: (record: any, fields: any) => string;
  showHeader?: boolean;
  customTabs?: any[];
  recordCallback?: (fields: any[]) => Promise<any>;
  showConfigurationSwitcher?: boolean;
  showBackButton?: boolean;
  backUrl?: string;
  moduleId?: string;
  forceReadonlyField?: FormConfiguration['forceReadonlyField'];
  onRecordChange?: (newRecord: any, oldRecord: any, originalRecord: any, module: any, setRecord: any) => any;
  autoSave?: number;
  resetTab?: boolean;
  trackTabInUrl?: boolean;
};

export type ApiBaseFormHandle<T = object> = {
  overrideValues: (overriddenValues?: { field: string; value: any }[]) => void;
  setRecord: (newRecord: Partial<Record<T>>) => void;
  getRecord: () => Record<T>;
  refresh: () => any;
};

type Record<T = object> = T & { ['@formValues']: { [key: string]: any } };

const computeTemplate = (moduleData: Module, template: Module['templates'][0] | null, templateId: string | null) => {
  if (moduleData?.templates && (!template || !moduleData?.templates.map(el => el['@id']).includes(template?.['@id']))) {
    if (moduleData.templates.length === 1) {
      return moduleData.templates[0];
    } else if (templateId) {
      const templateToSet = moduleData.templates.find(tmpTemplate => tmpTemplate.id === templateId);
      if (templateToSet) {
        return templateToSet;
      }
    }
  }

  return null;
};

const ApiBaseForm = forwardRef<ApiBaseFormHandle, BaseFromProps>((props, ref) => {
  const {
    onSubmit = (): Promise<boolean> => {
      throw 'You have to implement onSubmit prop';
    },
    readonly = undefined,
    contextActions = () => undefined,
    submitActions = () => undefined,
    inlineComponentAppendHeader = () => undefined,
    fieldComponents = {},
    sectionComponents = {},
    recordLabel = undefined,
    showHeader = true,
    customTabs = [],
    recordCallback = async () => ({ '@formValues': {} }),
    showConfigurationSwitcher = true,
    showBackButton = true,
    backUrl,
    forceReadonlyField,
    onRecordChange,
    autoSave,
    className,
    resetTab = false,
    trackTabInUrl = true,
  } = props;
  const templateId = useSearchParam('templateId');
  const { module, moduleData } = useModule();
  const [activeTab, setActiveTab] = useState<null | string | number>(null);
  const [tabs, setTabs] = useState<Template['tabs']>([]);
  const [templates, setTemplates] = useState<Module['templates']>(moduleData.templates);
  const [template, setTemplate] = useState<null | Module['templates'][0]>(computeTemplate(moduleData, null, templateId));
  const [record, setRecord] = useState<null | Record>(null);
  const [originalRecord, setOriginalRecord] = useState<null | Record>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [autoSaveTimeoutId, setAutoSaveTimeoutId] = useState<null | NodeJS.Timeout>(null);
  const [savedState, setSavedState] = useState<null | Record>(null);
  const selectedLanguage = useAppSelector(state => state.Root.language);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onRecordChangeMap = (recordNewState, recordOldState) => {
    if (!onRecordChange) {
      return recordNewState;
    }

    let newTmpState = onRecordChange(recordNewState, recordOldState, originalRecord, moduleData, setRecord);
    if (JSON.stringify(newTmpState) === JSON.stringify(recordNewState)) {
      newTmpState = recordNewState;
    }

    return newTmpState;
  };
  useImperativeHandle(ref, () => ({
    overrideValues(overriddenValues) {
      if (!overriddenValues?.length) {
        return;
      }
      setRecord(prevRecord => {
        if (!moduleData || !prevRecord) {
          return { ['@formValues']: {} };
        }
        let changes = {};

        overriddenValues.map(item => {
          const fieldConfiguration = moduleData.fields.find(el => el.propertyPath === item.field);
          if (fieldConfiguration) {
            changes = {
              ...changes,
              '@formValues': {
                ...(record?.['@formValues'] ?? {}),
                ...(changes['@formValues'] ?? {}),
                [fieldConfiguration.id]: item.value,
              },
              ...(fieldConfiguration.system ? setSystemFieldValue(fieldConfiguration.propertyPath, item.value, prevRecord) : {}),
            };
          }
        });

        return { ...prevRecord, ...changes };
      });
    },
    setRecord(newRecord) {
      setRecord(prevRecord => {
        if (!prevRecord) {
          return { ['@formValues']: {}, ...newRecord };
        }
        return { ...prevRecord, ...newRecord };
      });
    },
    getRecord() {
      if (!record) {
        throw 'Record not set!';
      }
      return { ...record, '@template': template?.['@id'] };
    },
    async refresh() {
      await fetchRecord(false);
    },
  }));

  useEffect(() => {
    if (trackTabInUrl) {
      const urlSearchParams = new URLSearchParams(location.search);
      const tab = urlSearchParams.get('tab');

      if (tab && !resetTab) {
        toggle(/^\d+$/.test(tab) ? parseInt(tab) : tab);
      }
    }
  }, []);

  const toggle = useCallback(
    (tab: string | number) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
        if (trackTabInUrl) {
          addParameterToUrl(tab);
        }
      }
    },
    [activeTab, trackTabInUrl],
  );

  const addParameterToUrl = tab => {
    const currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has('tab')) {
      currentUrl.searchParams.set('tab', tab);
    } else {
      currentUrl.searchParams.append('tab', tab);
    }
    history.push(currentUrl.pathname + currentUrl.search);
  };

  const fetchRecord = useCallback(
    async (clearExistingErrors = false) => {
      if (clearExistingErrors) {
        dispatch(clearErrors());
      }
      const fetchedRecord = await recordCallback(moduleData?.fields ?? []);
      if (fetchedRecord.hasOwnProperty('@id')) {
        if (fetchedRecord.hasOwnProperty('@template') && fetchedRecord['@template'] !== null) {
          setTemplate(fetchedRecord['@template']);
        } else {
          setTemplate(templates.find(tmpTemplate => tmpTemplate.system) ?? null);
        }
        setRecord(fetchedRecord);
        const data = { ...fetchedRecord, '@template': fetchedRecord?.['@template']?.['@id'] };
        setSavedState(prevState => {
          if (JSON.stringify(data) !== JSON.stringify(prevState)) {
            return data;
          }
          return prevState;
        });
        setOriginalRecord(fetchedRecord);
      } else {
        let defaultRecord = {};
        if (template?.id) {
          defaultRecord = await get(TemplateModule.configuration.api.item.getRecord({ id: template?.id }));
        }

        const newRecordState = {
          ...defaultRecord,
          ...fetchedRecord,
          '@formValues': {
            ...defaultRecord['@formValues'],
            ...fetchedRecord['@formValues'],
          },
        };
        setRecord(newRecordState);
        setOriginalRecord(newRecordState);
      }
    },
    [moduleData, recordCallback, dispatch, template?.id],
  );

  const fetchTemplateTabs = useCallback(async () => {
    if (template?.id) {
      TemplateModule.api.get({ id: template?.id }).then(async res => {
        setTabs(res.tabs ?? []);
        const defaultRecord = await get(TemplateModule.configuration.api.item.getRecord({ id: template?.id }));
        setRecord(prevRecord => {
          if (prevRecord && !prevRecord.hasOwnProperty('@id')) {
            return {
              ...defaultRecord,
              ...prevRecord,
              '@formValues': {
                ...(defaultRecord?.['@formValues'] ?? {}),
                ...(prevRecord?.['@formValues'] ?? {}),
              },
            };
          }

          return prevRecord;
        });
      });
    }
  }, [template?.id]);

  const setSystemFieldValue = useCallback((propertyPath, value, prevRecord) => {
    const key = propertyPath.split('.');

    if (key.length < 2) {
      return {
        [propertyPath]: value,
      };
    }

    const object = { [key[0]]: prevRecord[key[0]] } || {};
    setValue(object, propertyPath, value);

    return object;
  }, []);

  useEffect(() => {
    if (tabs?.length > 0 && (activeTab === null || (`${activeTab}`.length >= 10 && !tabs.map(el => el.id).includes(`${activeTab}`)))) {
      toggle(tabs[0]?.id);
    }
  }, [tabs, activeTab, toggle]);

  useEffect(() => {
    if (template?.id) {
      fetchTemplateTabs();
    }
  }, [template]);

  useEffect(() => {
    if (moduleData.templates) {
      setTemplates(moduleData.templates);
    }
    if (moduleData?.templates && (!template || !moduleData?.templates.map(el => el['@id']).includes(template?.['@id']))) {
      setTemplate(computeTemplate(moduleData, template, templateId));
    }
  }, [moduleData, template]);

  useEffect(() => {
    if (moduleData?.templates && (!template || !moduleData?.templates.map(el => el['@id']).includes(template?.['@id']))) {
      setTemplate(computeTemplate(moduleData, template, templateId));
    }
  }, []);

  useEffect(() => {
    if (onSubmit && autoSave && autoSave > 0) {
      if (autoSaveTimeoutId) {
        clearTimeout(autoSaveTimeoutId);
      }
      const timeoutId = setTimeout(async () => {
        const data = { ...(record ?? { ['@formValues']: {} }), '@template': template?.['@id'] };
        if (JSON.stringify(data) !== JSON.stringify(savedState)) {
          setSavedState(prevState => {
            if (JSON.stringify(data) !== JSON.stringify(prevState)) {
              return data;
            }
            return prevState;
          });
          const statusOfSave = await onSubmit(data, moduleData?.fields ?? []);
          if (statusOfSave) {
            await fetchRecord(false);
          }
        }
      }, autoSave);
      setAutoSaveTimeoutId(timeoutId);
    }
  }, [autoSave, record, template, savedState]);

  const loadRecord = useCallback(async () => {
    if (null === record) {
      await fetchRecord(true);
    }
  }, [record, fetchRecord]);

  useEffect(() => {
    loadRecord();
  }, [loadRecord]);

  const handleChangeTemplate = (newTemplate: string) => {
    const newTemplateObject = templates.find(tmpTemplate => tmpTemplate.id === newTemplate);
    if (newTemplateObject) {
      setTemplate(newTemplateObject);
    }
  };
  const handleSubmit = async (e, backToList: boolean) => {
    if (!record || !onSubmit) {
      return;
    }
    setIsSubmitting(true);
    e.preventDefault();
    e.stopPropagation();
    dispatch(clearErrors());
    if (autoSaveTimeoutId) {
      clearTimeout(autoSaveTimeoutId);
    }
    try {
      const data = { ...record, '@template': template?.['@id'] };
      setSavedState(prevState => {
        if (JSON.stringify(data) !== JSON.stringify(prevState)) {
          return data;
        }
        return prevState;
      });
      const statusOfSave = await onSubmit(data, moduleData?.fields ?? []);

      if (statusOfSave) {
        if (backToList) {
          if (backUrl) {
            history.push(backUrl);
          } else {
            history.goBack();
          }
        }
        await fetchRecord(false);
      }
    } catch (e) {
      console.error(e);
    }
    setIsSubmitting(false);
  };

  const updateCustomFormValue = useCallback(
    (value: any, field: string) => {
      if (!module) {
        throw 'Module not set!';
      }
      const fieldConfiguration = moduleData.fields.find(el => el.id === field);
      if (!fieldConfiguration) {
        throw 'Field not found in module!';
      }
      setRecord(prevRecord => {
        let computedNewRecord: Record<any>;
        if (fieldConfiguration.multiLanguage) {
          computedNewRecord = {
            ...prevRecord,
            '@formValues': {
              ...prevRecord?.['@formValues'],
              [field]: {
                '@ContentTranslation': true,
                value: {
                  ...prevRecord?.['@formValues']?.[field]?.value,
                  [selectedLanguage]: value,
                },
              },
            },
            ...(fieldConfiguration.system && selectedLanguage === 'pl'
              ? setSystemFieldValue(fieldConfiguration.propertyPath, value, prevRecord)
              : {}),
          };
        } else {
          computedNewRecord = {
            ...prevRecord,
            '@formValues': { ...prevRecord?.['@formValues'], [field]: value },
            ...(fieldConfiguration.system && selectedLanguage === 'pl'
              ? setSystemFieldValue(fieldConfiguration.propertyPath, value, prevRecord)
              : {}),
          };
        }
        return onRecordChangeMap(computedNewRecord, prevRecord);
      });
    },
    [moduleData?.fields, setSystemFieldValue, selectedLanguage],
  );

  const updateRecord = useCallback(
    newRecord => {
      if (typeof newRecord === 'function') {
        setRecord(prevRecord => {
          const computedNewRecord = newRecord(prevRecord, moduleData?.fields ?? []);
          return onRecordChangeMap(computedNewRecord, prevRecord);
        });
      } else {
        setRecord(prevRecord => {
          const computedNewRecord = { ...prevRecord, ...newRecord };
          return onRecordChangeMap(computedNewRecord, prevRecord);
        });
      }
    },
    [JSON.stringify(moduleData?.fields)],
  );

  if (!template && moduleData?.templates && moduleData?.templates.length > 1 && record) {
    return <TemplateSelector templates={moduleData.templates} onSelectTemplate={setTemplate} />;
  }

  if (!template || !record || !module) {
    return <Loader />;
  }

  const tabsToDisplay = tabs.filter(tabToCheck => {
    let hideTab = false;

    if (tabToCheck.hideRules && tabToCheck.hideRules.length > 0) {
      const resolvedConditions = tabToCheck.hideRules.map(hideRule => {
        const left = record['@formValues']?.[hideRule.field]?.['@id'] ?? record['@formValues']?.[hideRule.field];
        const right = hideRule.value;

        switch (hideRule.cmp) {
          case 'eq':
            return left === right || (!left && !right);
          case 'neq':
            return left !== right;
          case 'like':
            return (left + '').toLowerCase().includes((right + '').toLowerCase());
          case 'nlike':
            return !(left + '').toLowerCase().includes((right + '').toLowerCase());
          default:
            return false;
        }
      });

      hideTab = resolvedConditions.filter(el => el).length === tabToCheck.hideRules.length;
    }

    return !hideTab;
  });

  const allowedCustomTabs = customTabs.filter(ct => !ct.isAllowedToDisplay || ct.isAllowedToDisplay(record));

  return (
    <div
      className={classnames(`api-form ${className}`, { 'short-header': readonly && !showConfigurationSwitcher })}
      onContextMenu={e => e.stopPropagation()}
    >
      {showHeader && (
        <ApiBaseFormHeader
          readonly={true === readonly}
          template={template}
          recordLabel={recordLabel}
          record={record}
          templates={templates}
          handleChangeTemplate={handleChangeTemplate}
          contextActions={contextActions}
          showBackButton={showBackButton}
          backUrl={backUrl}
          showConfigurationSwitcher={showConfigurationSwitcher}
          handleSubmit={handleSubmit}
          inlineComponentAppendHeader={inlineComponentAppendHeader}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          templateBuilder={{
            afterSave: () => {
              fetchTemplateTabs();
              // fetchModule(moduleId);
            },
          }}
        />
      )}
      <>
        <Row className={'form-tabs'}>
          <Col>
            <Nav tabs className="bg-dark-accent border-radius-top position-relative">
              {tabsToDisplay.map(el => (
                <TabNavLink
                  key={`NavItem_${el.id}`}
                  tab={el}
                  activeTab={activeTab ?? '_'}
                  onClick={() => {
                    toggle(el.id);
                  }}
                  onDoubleClick={() => false}
                />
              ))}
              {allowedCustomTabs.map((el, i) => (
                <NavItem key={`NavItem_${i}`}>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      active: activeTab === i,
                      'dark-accent-color': activeTab !== i,
                    })}
                    onClick={() => {
                      toggle(i);
                    }}
                  >
                    <span>{el.title}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            {record && moduleData && (
              <Card className="border-small border-top-0 border-radius-top-left-none">
                <CardBody style={{ padding: 0 }}>
                  <TabContent activeTab={activeTab ?? '_'} className="text-muted">
                    {tabsToDisplay.map(el => (
                      <TabPane tabId={el.id} key={`TabPane${el.id}`}>
                        {activeTab === el.id && (
                          <CustomTab
                            tab={el}
                            values={record['@formValues']}
                            onUpdate={updateCustomFormValue}
                            fieldComponents={fieldComponents ?? {}}
                            sectionComponents={sectionComponents ?? {}}
                            readonly={true === readonly}
                            recordId={record?.['@id']}
                            forceReadonlyField={forceReadonlyField}
                          />
                        )}
                      </TabPane>
                    ))}
                    {allowedCustomTabs.map((el, index) => (
                      <TabPane tabId={index} key={`CTabPane${index}`}>
                        {activeTab === index && (
                          <el.component
                            {...(el.props ?? {})}
                            tab={el}
                            record={record}
                            module={moduleData}
                            onUpdate={updateRecord}
                            fields={moduleData.fields}
                            readonly={readonly}
                            recordId={record?.['@id']}
                          />
                        )}
                      </TabPane>
                    ))}
                  </TabContent>
                  {onSubmit !== false && !readonly && (
                    <div style={{ position: 'sticky', bottom: 0, zIndex: 460 }}>
                      <Paper className="col-md-12 p-3" variant="outlined">
                        <div className="d-flex space-between">
                          <div className="w-100 d-flex align-items-center">
                            <span>
                              {moduleData.name}{' '}
                              {recordLabel && recordLabel(record, moduleData.fields) ? `- ${recordLabel(record, moduleData.fields)}` : ''}{' '}
                            </span>
                          </div>
                          <div className="w-100 text-end">
                            {submitActions(record)}
                            <button
                              className="btn btn-primary ml-1"
                              type="submit"
                              disabled={isSubmitting}
                              onClick={e => handleSubmit(e, true)}
                            >
                              <i className={isSubmitting ? 'mdi mdi-spin mdi-cogs' : 'mdi mdi-arrow-left'} /> Zapisz i wróć do listy
                            </button>
                            <button
                              className="btn btn-primary ml-1"
                              type="submit"
                              disabled={isSubmitting}
                              onClick={e => handleSubmit(e, false)}
                            >
                              <i className={isSubmitting ? 'mdi mdi-spin mdi-cogs' : 'mdi mdi-content-save'} /> Zapisz
                            </button>
                          </div>
                        </div>
                      </Paper>
                    </div>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </>
    </div>
  );
});
ApiBaseForm.displayName = 'ApiBaseForm';

export default ApiBaseForm;
