import { TextField } from '@mui/material';
import { ProductGroupModule } from 'Modules/CTMModules';
import { ProductGroupType } from 'Modules/Manufacture/Types/ProductGroup';
import { confirmRemove } from 'common/sweetalerts';
import ModalFormWrapper from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import { FC, useRef, useState } from 'react';
import 'react-sortable-tree/style.css';
import { Button, ButtonGroup } from 'reactstrap';

interface TreeNodeProps {
  node: ProductGroupType;
  path: string[] | number[];
  editNode: (path: string[] | number[], node: ProductGroupType, title: string) => void;
  removeNode: (path: string[] | number[], node: ProductGroupType) => Promise<boolean>;
}

const TreeNode: FC<TreeNodeProps> = ({ node, path, editNode, removeNode }) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [editedName, setEditName] = useState<string | undefined>(node.name);
  const editModal = useRef<any>();

  const handleClick = (): void => {
    setEditing(true);
  };

  const handleBlur = (): void => {
    setEditing(false);

    if (!editedName) {
      return;
    }

    editNode(path, node, editedName);
  };

  return (
    <div style={{ width: '250px' }}>
      {isEditing ? (
        <div>
          {editedName}
          <TextField
            name={node.id ?? ''}
            variant={'outlined'}
            value={editedName}
            onChange={e => setEditName(e.target.value)}
            onBlur={handleBlur}
            autoFocus
          />
        </div>
      ) : (
        <div>
          <div style={{ marginRight: '50px', overflow: 'scroll' }} onDoubleClick={handleClick}>
            {node.name}
          </div>
          <ButtonGroup style={{ position: 'absolute', right: '5px', top: '6px' }}>
            {node.id && (
              <ModalFormWrapper
                ref={editModal}
                title={`${ProductGroupModule?.configuration?.name} - ${node.name} - Edytuj`}
                form={
                  <ModuleForm
                    moduleName={ProductGroupModule.configuration.urlPrefix}
                    id={node.id}
                    showContextActions={false}
                    showBackButton={false}
                    showConfigurationSwitcher={true}
                    afterSave={() => {
                      editModal.current.close();
                    }}
                    readonly={false}
                  />
                }
              >
                <Button clasSName="btn btn-info btn-sm">
                  <i className="mdi mdi-pencil" />
                </Button>
              </ModalFormWrapper>
            )}
            <Button onClick={() => confirmRemove(() => removeNode(path, node))} className="btn btn-danger btn-sm">
              <i className="mdi mdi-delete" />
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default TreeNode;
