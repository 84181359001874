import { HighlightOff } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import DataGrid, { DataGridRef } from 'components/DataGrid';
import { ComponentRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';

type ListSelectorProps = {
  dataGridProps?: any;
  children?: any;
  disabled?: boolean;
  title: string;
  navbar: any;
  dialogProps?: Partial<DialogProps>;
};

type listSelectorHandle = {
  open: (parameters?: any[]) => void;
  close: () => void;
  refresh: () => void;
  getVisibleData: () => any;
};

export type ListSelectorRef = ComponentRef<typeof ListSelector>;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
  },
}));

const ListSelector = forwardRef<listSelectorHandle, ListSelectorProps>((props, ref) => {
  const { dataGridProps = {}, children = undefined, disabled = false, dialogProps = {}, title, navbar } = props;
  const [open, setOpen] = useState(false);
  const [forcedFilters, setForcedFilters] = useState<any[]>([]);
  const dataGridRef = useRef<DataGridRef>();
  useImperativeHandle(ref, () => ({
    open(parameters) {
      setOpen(true);
      if (parameters) {
        setForcedFilters(parameters);
      }
    },
    close() {
      setOpen(false);
    },
    refresh() {
      dataGridRef.current?.refresh();
    },
    getVisibleData() {
      return dataGridRef.current?.getData();
    },
  }));

  return (
    <>
      <div
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          !disabled && setOpen(true);
        }}
      >
        {children}
      </div>
      {open && (
        <StyledDialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'xl'} {...dialogProps}>
          <DialogTitle
            sx={theme => ({
              m: 0,
              p: 1,
              color: theme.palette.common.white,
              background: '#01579b',
            })}
          >
            {title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={theme => ({
              position: 'absolute',
              right: 8,
              top: 4,
              color: theme.palette.common.white,
            })}
          >
            <HighlightOff />
          </IconButton>
          <DialogContent dividers>
            {open && (
              <DataGrid
                ref={dataGridRef}
                {...{ ...dataGridProps, defaultFilters: forcedFilters.length > 0 ? forcedFilters : dataGridProps.defaultFilters }}
              />
            )}
          </DialogContent>
          <DialogActions>{navbar}</DialogActions>
        </StyledDialog>
      )}
    </>
  );
});
ListSelector.displayName = 'ListSelector';

export default ListSelector;
