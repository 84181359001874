import * as DocumentApi from '../../helpers/Api/Warehouse/Document/Document';
import Contractor from '../Contractor/Contractor';
import Product, { Product as ProductModel } from '../Manufacture/Product';
import DocumentType from './DocumentType';
import Place from './Place';
import Warehouse from './Warehouse';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { SelectApiColumnFilter } from 'components/DataGrid/Filter';
import dayjs from 'dayjs';
import { createModuleColumnConfig } from 'helpers/ModuleUtils';

export type PlaceEvent = CTMRecord & {
  name?: string;
};

export const placeEvents = {
  STORE: 'Przyjęcie na magazyn',
  SUBTRACT: 'Zdjęcie z magazynu',
  RESERVATION_WAREHOUSE: 'Rezerwacja na magazynie',
  RESERVATION_ORDER: 'Rezerwacja na zamówieniu',
  RESERVATION_WAREHOUSE_COMPLETED: 'Zamknięcie rezerwacji na magazynie',
  RESERVATION_ORDER_COMPLETED: 'Zamknięcie rezerwacji na zamówieniu',
};

const columns: CTMListColumn<PlaceEvent>[] = [
  {
    id: 'document.id',
    Header: 'Dokument',
    accessor: 'document.number',
    filterable: true,
    sortable: true,
    Filter: SelectApiColumnFilter,
    filterSearchUrl: '/warehouse/documents',
    filterSearchField: 'id',
    filterFetchByValue: DocumentApi.fetch,
  },
  {
    id: 'document.type.id',
    Header: 'Rodzaj dokumentu',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('document.type', DocumentType),
  },
  {
    id: 'type',
    Header: 'Rodzaj zdarzenia',
    accessor: ({ type }) => placeEvents[type],
    filterable: false,
    sortable: true,
  },
  {
    id: 'product.id',
    Header: 'Towar',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig<ProductModel>('product', Product),
  },
  {
    id: 'quantityBefore',
    Header: 'Ilość przez zdarzeniem',
    accessor: ({ quantityBefore, unit }) => `${quantityBefore} ${unit?.name ?? 'szt.'}`,
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'quantity',
    Header: 'Ilość',
    accessor: ({ quantity, unit }) => `${quantity} ${unit?.name ?? 'szt.'}`,
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'unitPriceGross',
    Header: 'Cena jed. brutto',
    accessor: ({ unitPriceGross, unit }) => `${(unitPriceGross / 100).toFixed(2)}zł/${unit?.name ?? 'szt.'}`,
    filterable: false,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'storedAt',
    Header: 'Data zdarzenia',
    accessor: ({ storedAt }) => (storedAt ? dayjs(storedAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    minWidth: 160,
    noBreak: true,
  },
  {
    id: 'supplier',
    Header: 'Dostawca',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('document.supplier', Contractor),
  },
  {
    id: 'recipient',
    Header: 'Odbiorca',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('document.recipient', Contractor),
  },
  {
    id: 'place.warehouse.id',
    Header: 'Magazyn',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('place.warehouse', Warehouse),
  },
  {
    id: 'place.id',
    Header: 'Miejsce magazynowe',
    filterable: true,
    sortable: true,
    width: 50,
    ...createModuleColumnConfig('place', Place),
  },
];

const module: CTMModule<PlaceEvent> = {
  id: '46177073-55d0-42b3-88ec-9c9e7620acf7',
  dataClass: 'CTM\\Warehouse\\Entity\\Warehouse\\PlaceEvent',
  urlPrefix: 'warehouse-place-events',
  name: 'Zdarzenia magazynowe',
  role: 'WAREHOUSE_DOCUMENT',
  api: {
    item: {
      get: ({ id }) => `/warehouse/place-events/${id}`,
      put: ({ id }) => `/warehouse/place-events/${id}`,
      delete: ({ id }) => `/warehouse/place-events/${id}`,
    },
    collection: {
      get: `/warehouse/place-events`,
      post: `/warehouse/place-events`,
    },
  },
  recordLabel: ({ name }) => name,
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'storedAt', desc: true }],
  },
};

export default module;
