import ContextMenu from './ContextMenu';
import DuplicatePartOfTree from './DuplicatePartOfTree';
import FlowProductNode from './FlowProductNode';
import FlowTechnologyLabelNode from './FlowTechnologyLabelNode';
import {
  Node,
  PRODUCT_BLOCK_WIDTH_RESERVED,
  ProductNode,
  TechnologyNode,
  TreeIngredients,
  buildMapFromTechnologies,
  buildTreeFromProduct,
  buildTreeLayout,
  computeMaxFlowColumnWidthFactor,
  extractTechnologiesFromProduct,
  updateSystemFieldValueOnProduct,
} from './FlowUtils';
import SelectionGuard from './SelectionGuard';
import TechnologyEdge from './TechnologyEdge';
import useStore, { ProductNodeModeBasic, ProductNodeModeTechnologyFiles } from './store';
import Loader from '@Components/Theme/Common/Loader';
import { ArrowLeft, ArrowRight, Fullscreen } from '@mui/icons-material';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Field } from 'Modules/CustomFields/Field';
import { Product } from 'Modules/Manufacture/Product';
import { Technology } from 'Modules/Manufacture/Types/Technology';
import ModalFormWrapper, { ModalFormWrapperRef } from 'components/Form/ModalFormWrapper';
import SelectMultiple from 'components/Form/SelectMultiple';
import ModuleForm from 'components/Module/ModuleForm';
import ModuleListPicker, { ModuleListPickerRef } from 'components/Module/ModuleListPicker';
import { axiosApi } from 'helpers/Axios';
import { FC, createRef, useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  Edge,
  EdgeTypes,
  MarkerType,
  MiniMap,
  NodeTypes,
  Panel,
  useReactFlow,
} from 'reactflow';
import { useAppDispatch } from 'store';
import { addSingleToast } from 'store/Toast/actions';
import { v4 as uuidv4 } from 'uuid';
// import { v4 as uuidv4 } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

const nodeTypes: NodeTypes = {
  productNode: FlowProductNode,
  technologyNodeLabel: FlowTechnologyLabelNode,
};
const edgeTypes: EdgeTypes = {
  TechnologyEdge: TechnologyEdge,
};

interface FlowProps {
  technologies: Technology[];
  fields: Field[];
  rootProduct: any;
  onUpdate: (newData: object | ((prevData: Product, moduleFields: any[]) => Product)) => void;
}

function extractTechnologiesFromProducts(products: any[]): string[] {
  const ids: string[] = [];

  products.forEach(product => {
    if (product.technology) {
      ids.push(product.technology['@id']);
    }
  });
  return ids;
}

const Flow: FC<FlowProps> = ({ technologies, rootProduct, onUpdate, fields }) => {
  const { fitView, setViewport } = useReactFlow();
  const productsSelector = createRef<ModuleListPickerRef>();
  const [selectedTechnologies, setSelectedTechnologies] = useState<string[]>(extractTechnologiesFromProduct(rootProduct));
  const [panelActive, setPanelActive] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const togglePanelActive = () => setPanelActive(!panelActive);
  const flowStore = useStore(
    useShallow(state => ({
      nodes: state.nodes,
      edges: state.edges,
      connectingNodeId: state.connectingNodeId,
      onNodesChange: state.onNodesChange,
      onEdgesChange: state.onEdgesChange,
      productNodeMode: state.productNodeMode,
      onConnect: state.onConnect,
      updateState: state.updateState,
      resetState: state.resetState,
      onConnectStart: state.onConnectStart,
      setNodes: state.setNodes,
      setEdges: state.setEdges,
      setTechnologies: state.setTechnologies,
    })),
  );
  const {
    nodes,
    edges,
    connectingNodeId,
    setNodes,
    setEdges,
    updateState,
    productNodeMode,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onConnectStart,
    setTechnologies,
    resetState,
  } = flowStore;

  const copyProduct = (productId): Promise<any> => {
    return axiosApi.post(`manufacture/products/copy`, { id: productId });
  };
  const selectedTechnologiesAsObjects = (): Technology[] => technologies.filter(el => selectedTechnologies.includes(el['@id']));
  const [menu, setMenu] = useState<any>(null);
  const ref = useRef<any>(null);
  const showModal = createRef<ModalFormWrapperRef>();

  useEffect(() => {
    const preparedTree: TreeIngredients = { nodes: [], edges: [] };
    buildTreeFromProduct(
      { id: '', productFilter: undefined, quantity: 1, unit: rootProduct.unit, used: 0, product: rootProduct },
      preparedTree,
      null,
    );
    resetState();
    updateState({ fields });
    setNodes([...buildMapFromTechnologies(selectedTechnologiesAsObjects()), ...preparedTree.nodes]);
    setEdges(preparedTree.edges);
    setTechnologies(technologies);
  }, []);
  const onLayout = useCallback(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = buildTreeLayout(nodes, edges);

    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);

    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [nodes, edges]);
  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();

      if (node?.type === 'group') {
        return;
      }

      const pane = ref?.current?.getBoundingClientRect() ?? { width: 0, height: 0 };
      setMenu({
        id: node?.id,
        // top: event.clientY - offset().top,
        // left: event.clientX - offset().left + 20,
        // right: event.clientX - offset().left + 220,
        // bottom: event.clientY - offset().top + 200,
        top: event.clientY,
        left: event.clientX + 20,
        right: event.clientX + 220,
        bottom: event.clientY + 200,
        // top: event.clientY,
        // left: event.clientX,
        // right: event.clientX,
        // bottom: event.clientY,
      });
    },
    [setMenu],
  );

  const onTechnologiesChange = useCallback(
    newTechnologiesIds => {
      const newNodes = [
        ...nodes.filter(el => !['group', 'technologyNodeLabel'].includes(el.type ?? '')),
        ...buildMapFromTechnologies(technologies.filter(el => newTechnologiesIds.includes(el['@id']))),
      ];

      const nodesIds = newNodes.map(el => el.id);

      const widthFactor = computeMaxFlowColumnWidthFactor(edges, `${rootProduct['@id']}-rp`);
      newNodes.forEach((el, i) => {
        if (el.type === 'group' && typeof el?.style?.width !== 'undefined') {
          el.style.width = PRODUCT_BLOCK_WIDTH_RESERVED * (widthFactor + 2) * 1.15;
        }
      });
      const removedNodesIds: string[] = [];
      const newNodesFiltered = newNodes.filter(el => {
        const shouldNotBeRemoved = !el.parentId || nodesIds.includes(el.parentId);
        if (!shouldNotBeRemoved) {
          removedNodesIds.push(el.id ?? '');
        }
        return shouldNotBeRemoved;
      });

      const newEdges = removedNodesIds.reduce<Edge[]>((acc, el) => {
        const sourceEdges = edges.filter(edge => edge.source === el);
        const targetEdges = edges.filter(edge => edge.target === el);
        targetEdges.forEach(targetEdge => {
          sourceEdges.forEach(sourceEdge => {
            acc.push({
              id: `${sourceEdge.target}${targetEdge.source}`,
              target: sourceEdge.target,
              source: targetEdge.source,
              type: 'step',
              deletable: false,
              animated: true,
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            } as Edge);
          });
        });

        return acc;
      }, []);

      const settableNewEdges = edges
        .filter(el => !removedNodesIds.includes(el.source) && !removedNodesIds.includes(el.target))
        .concat(newEdges);
      setEdges(settableNewEdges);
      setNodes(newNodesFiltered);

      setSelectedTechnologies(newTechnologiesIds);
    },
    [selectedTechnologies, setSelectedTechnologies, nodes, setNodes, edges, setEdges, setNodes, technologies, rootProduct?.['@id']],
  );

  const onFlowConnectionEnd = ev => {
    if (!connectingNodeId) return;

    //@ts-ignore
    const targetIsPane = ev.target.classList.contains('react-flow__node-group');

    if (targetIsPane) {
      const id = uuidv4();
      //@ts-ignore
      const targetId = event.target.getAttribute('data-id');
      const targetNode = nodes.find(n => n.id === targetId) as TechnologyNode | undefined;
      const sourceNode = nodes.find(n => n.id === connectingNodeId) as ProductNode | undefined;
      if (sourceNode === undefined || targetNode === undefined || targetNode.type != 'group') {
        return;
      }
      let targetTechnology: null | Technology = null;
      const sourceNodeTopEdges = edges.filter(el => el.source === connectingNodeId).map(el => el.target);
      if (sourceNodeTopEdges.length > 0) {
        const sourceNodeParents = nodes.filter(el => sourceNodeTopEdges.includes(el.id ?? ''));
        const hasParentInTargetLevel = sourceNodeParents.find(el => el.parentId === targetId);
        targetTechnology = technologies.find(el => el['@id'] === targetNode.id) as Technology;
        const targetTechnologyPosition = targetTechnology?.position ?? 0;
        const sourceParentGroups = sourceNodeParents.map(el => el.parentId);
        const sourceParentTechnologiesMinimalPosition = Math.min(
          ...technologies.filter(el => sourceParentGroups.includes(el['@id'])).map(el => el.position),
          99999,
        );

        if (hasParentInTargetLevel || targetTechnologyPosition > sourceParentTechnologiesMinimalPosition) {
          dispatch(
            addSingleToast({
              title: `Składnik "${sourceNode?.data.product?.name}" Jest już składnikiem innego towaru, jeśli chcesz go użyć ponownie do innego połączenia dodaj go po raz kolejny.`,
              config: { appearance: 'error' },
            }),
          );
          return;
        }
      }

      const buildToDown =
        sourceNode.data.root ||
        targetNode.data.ingredients ||
        technologies.find(el => el['@id'] === sourceNode.parentId)?.position >
          technologies.find(el => el['@id'] === targetNode.id)?.position;
      if (buildToDown) {
        productsSelector.current?.open([{ id: 'technology.id', value: targetNode.id.split('/').pop() }]);
        // setTimeout(() => {
        //   productsSelector.current?.setAllFilters([{ id: 'technology.id', value: targetNode.data.id }]);
        // }, 3000);
      } else {
        copyProduct(sourceNode?.data.product?.id ?? '')
          .then(({ data: newProduct }) => {
            const newNode = {
              ...JSON.parse(JSON.stringify(sourceNode)),
              id,
              positionAbsolute: undefined,
              position: { ...sourceNode.position },
              selected: false,
              selectable: true,
              dragging: false,
              parentId: targetNode.id,
              data: { product: { ...newProduct, technology: targetTechnology ?? null }, root: false },
              // data: { product: { ...newProduct }, root: false },
              origin: [0.5, 0.0],
            } as ProductNode;
            const { nodes: layoutedNodes, edges: layoutedEdges } = buildTreeLayout(
              [...nodes, newNode],
              [
                ...edges.map(el => {
                  if (el.source === connectingNodeId) {
                    return { ...el, data: { ...(el.data ?? {}) }, source: id };
                  }
                  return el;
                }),
                {
                  id: uuidv4(),
                  source: connectingNodeId,
                  target: id,
                  animated: true,
                  type: 'step',
                  markerEnd: {
                    type: MarkerType.ArrowClosed,
                  },
                },
              ],
            );
            updateState({
              connectingNodeId: null,
              nodes: layoutedNodes,
              edges: layoutedEdges,
            });

            const newNodePosition = layoutedNodes.find(el => el.id === id)?.position;

            if (newNodePosition) {
              setViewport({ x: newNodePosition.x * -1, y: newNodePosition.y, zoom: 1 });
            }
          })
          .catch(err => {
            dispatch(
              addSingleToast({
                title: `Nie udało się skopiować produktu. Spróbuj ponownie.`,
                config: { appearance: 'error' },
              }),
            );
            (err.response.data.violations ?? []).map(violation => {
              const field = fields.find(el => el.propertyPath === violation.propertyPath);
              dispatch(
                addSingleToast({
                  title: `${field?.name ?? violation.propertyPath}: ${violation.message}`,
                  config: { appearance: 'error' },
                }),
              );
            });
          });
      }
      // window.requestAnimationFrame(() => {
      //   fitView();
      // });
      // onLayout(undefined, false);
    }
  };

  const handleProductSelect = gridRowProduct => {
    if (!connectingNodeId) return;
    axiosApi.get<any, { data: Product }>(gridRowProduct['@id']).then(({ data: newProduct }) => {
      const connectingNode = nodes.find(el => el.id === connectingNodeId) as ProductNode;
      const sameNodeProducts = nodes
        .filter(el => el.type === 'productNode' && el.data.product?.id === connectingNode.data.product?.id)
        .map(el => el.id);
      const preparedTree: TreeIngredients = { nodes: [], edges: [] };
      sameNodeProducts.forEach(nodeToExtend => {
        buildTreeFromProduct(
          {
            ['@id']: uuidv4(),
            product: newProduct,
            quantity: 1,
            unit: newProduct?.unit,
            productFilter: null,
            id: uuidv4(),
          },
          preparedTree,
          nodeToExtend,
        );
      });
      const usedTechnologies: string[] = preparedTree.nodes.map(el => el.parentId ?? '').filter(el => el && el !== '0');
      const newTechnologies = usedTechnologies.filter(el => !selectedTechnologies.includes(el) && !newTechnologies.includes(el));
      const newSelectedTechnologies: string[] = [...selectedTechnologies, ...newTechnologies];
      const newTechnologyNodes: Node[] = buildMapFromTechnologies(technologies.filter(el => newSelectedTechnologies.includes(el['@id'])));

      const { nodes: layoutedNodes, edges: layoutedEdges } = buildTreeLayout(
        [...nodes.filter(el => !['group', 'technologyNodeLabel'].includes(el.type ?? '')), ...preparedTree.nodes, ...newTechnologyNodes],
        [...edges, ...preparedTree.edges],
      );
      updateState({
        connectingNodeId: null,
        nodes: layoutedNodes,
        edges: layoutedEdges,
      });
      setSelectedTechnologies(newSelectedTechnologies);
    });
  };

  const updateProduct = (product: Product, moduleFields: any[], nodes: ProductNode[], edges: Edge[]): Product => {
    const productNode = nodes.find(el => el.data?.product?.id === product.id);

    if (productNode === undefined) {
      return product;
    }

    const productEdges = edges.filter(el => el.target === productNode.id);

    const sourceNodes = productEdges.map(el => nodes.find(node => node.id === el.source)) as ProductNode[];

    let updatedProduct: Product = {
      ...product,
      technology: productNode.parentId,
      ...productNode.data.product,
      requiredProducts: sourceNodes.map(el => {
        if (!el?.data?.product) {
          return {
            ['@id']: el?.data?.requiredProductId ?? undefined,
            product: null,
            productFilter: null,
            quantity: el?.data?.quantity ?? 1,
            unit: el?.data?.unit,
          };
        } //todo nie działa dodanie rodzica
        return {
          ['@id']: el?.data?.requiredProductId ?? undefined,
          product: updateProduct(el?.data?.product, moduleFields, nodes, edges),
          productFilter: null,
          quantity: el?.data?.quantity ?? 1,
          unit: el?.data?.unit,
        };
      }),
    };

    updatedProduct = updateSystemFieldValueOnProduct(
      updatedProduct,
      moduleFields,
      'unit',
      productNode.data.unit ?? productNode.data.product?.unit ?? null,
      'pl',
    );

    updatedProduct = updateSystemFieldValueOnProduct(
      updatedProduct,
      moduleFields,
      'requiredProducts',
      sourceNodes.map(el => {
        if (!el?.data?.product) {
          return {
            product: null,
            productFilter: null,
            quantity: el?.data?.quantity ?? 1,
            unit: el?.data?.unit,
          };
        }
        return {
          product: updateProduct(el?.data?.product, moduleFields, nodes, edges),
          productFilter: null,
          quantity: el?.data?.quantity ?? 1,
          unit: el?.data?.unit,
        };
      }),
      'pl',
    );

    return updatedProduct;
  };

  const emmitStateToParent = () => {
    onUpdate((prevRecord, moduleFields) => {
      return {
        ...prevRecord,
        ...updateProduct(prevRecord, moduleFields, nodes.filter(el => el.type === 'productNode') as ProductNode[], edges),
      };
    });
  };

  if (nodes.length === 0) {
    return <Loader />;
  }

  //@ts-ignore
  return (
    <ReactFlow
      ref={ref}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
      onInit={onLayout}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onConnect={onConnect}
      onConnectStart={onConnectStart}
      onConnectEnd={onFlowConnectionEnd}
      style={{ background: '#2a3042' }}
      onBlur={emmitStateToParent}
      onNodeContextMenu={onNodeContextMenu}
      minZoom={0.1}
      maxZoom={10}
    >
      <Background />
      {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
      <MiniMap
        pannable={true}
        zoomable={true}
        zoomStep={2}
        maskStrokeColor={'#303030'}
        maskStrokeWidth={3}
        nodeColor={node => (node.type === 'productNode' ? node?.data?.product?.type?.color ?? '#303030' : 'rgba(0,0,0,.1)')}
      />
      <Controls>
        <ControlButton onClick={() => alert('Something magical just happened. ✨')}>
          <Fullscreen />
        </ControlButton>
      </Controls>

      <Panel position="top-right">
        <div className={`config-panel__toggler ${panelActive ? 'active' : ''}`} onClick={togglePanelActive}>
          {panelActive && (
            <>
              <ArrowRight />
              <ArrowRight />
              <ArrowRight />
            </>
          )}
          {!panelActive && (
            <>
              <ArrowLeft />
              <ArrowLeft />
              <ArrowLeft />
            </>
          )}
        </div>
        <div className={`config-panel ${panelActive ? 'active' : ''}`}>
          <div className="config-panel__content">
            <SelectMultiple<Technology>
              label={'Technologie wykorzystane w produkcji'}
              options={technologies}
              disableGroupMargin={true}
              isClearable={false}
              name={'images'}
              key={'images'}
              value={selectedTechnologies}
              onChange={onTechnologiesChange}
            />
            <Button variant="contained" onClick={() => onLayout()}>
              Popraw layout
            </Button>
          </div>
          <Stack spacing={2} alignItems="center">
            <ToggleButtonGroup
              size="small"
              value={productNodeMode}
              exclusive={true}
              onChange={(e, v) => updateState({ productNodeMode: v })}
              aria-label="Small sizes"
            >
              <ToggleButton value={ProductNodeModeBasic}>Konfiguracja ilość</ToggleButton>
              <ToggleButton value={ProductNodeModeTechnologyFiles}>Pliki technologii</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
            <ModuleListPicker<Product>
              ref={productsSelector}
              moduleName={'manufacture-products'}
              onChange={handleProductSelect}
              dataGridProps={{ storeFilters: false }}
            />
            <ModalFormWrapper
              ref={showModal}
              title={`Edycja`}
              form={
                <ModuleForm
                  moduleName={'manufacture-products'}
                  id={'194b3e8b-f211-414e-92e6-d21d8b452f0e'}
                  showContextActions={false}
                  showBackButton={false}
                  showConfigurationSwitcher={false}
                  overrideFormProps={{ trackTabInUrl: false }}
                  afterSave={() => {}}
                  readonly={true}
                />
              }
            >
              <Button className="btn btn-success btn-sm">
                <i className="mdi mdi-eye" style={{ padding: '0 4px' }} />
              </Button>
            </ModalFormWrapper>
          </div>
        </div>
      </Panel>
      <DuplicatePartOfTree />
      <SelectionGuard />
    </ReactFlow>
  );
};

export default Flow;
